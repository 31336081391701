export let TOUR_STEPS = [
  {
    target: 'span.powerback',
    content:
      "Welcome to POWERBACK, the revolution in online political campaign support! Let's get started.",
    disableBeacon: true,
  },
  {
    target: '#desktop-explainer-btn button',
    content:
      'You may click this button to watch a 27-second video explaining how POWERBACK works. You can resume the tour afterward.',
    data: 'lg',
  },
  {
    target: '#choose-pols',
    content:
      "Right now, we're interested in incumbent members of the House of Representatives, because that's who's there. Let's explore the search options we can use to filter them down.",
  },
  {
    target: '.list-group-horizontal',
    content:
      'By default the search bar is set to look up by Name. You can search by State as well, or find any District in the country via a street address plus the ZIP code.',
  },
  {
    target: '#show-cosponsors-lg, .selector-bar div.hstack',
    content:
      'You can also filter out anyone who isn\'t a "cosponsor" of Medicare For All, which is just like an extra step they\'ve taken to show their support for the legislation. Try it out.',
  },

  {
    target: 'div.mt-1.col-2 span.navbar-brand img',
    content:
      'If you miss anything, touch our logo to open the sidebar menu to access everything you need, including FAQ, Eligibility rules, our Terms, and our short explainer video.',
    data: 'sm',
  },
  {
    target: 'div.pol-carousel.pol-row.row',
    content:
      'After narrowing it down and playing the field a bit, make your choice and start a Celebration!',
    data: 'lg',
  },
  {
    target: '.celebration-middle',
    content:
      'Swipe left and right to browse after applying any of your filters. Make your choice and start a Celebration!',
    data: 'sm',
  },
];
