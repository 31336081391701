import React, { SetStateAction, Dispatch } from 'react';
import { NavTabLinks as Links } from '@Components/interactive';
import { ShowAlert, ShowModal } from '@Interfaces';
import { GenericBtn } from '@Components/buttons';
import Navbar from 'react-bootstrap/esm/Navbar';
import { CredentialsPath, Props } from '@Types';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { User, Brand } from './sections';
import { APP } from '@CONSTANTS';
import './style.css';

const Navigation = ({
  setCredentialsPath,
  serverHasError,
  setShowSideNav,
  handleLogOut,
  setShowAlert,
  setShowModal,
  showSideNav,
  isDesktop,
  isMobile,
  loggedIn,
  splash,
  route,
  ...props
}: Props) => {
  return (
    <Navbar id='navbar'>
      <Row>
        {(isDesktop ||
          (route.name === 'main' && !loggedIn && splash !== 'Tour')) && (
          <>
            <Col lg={4}>
              <Links
                LINK_LABELS={APP.NAV.MODALS}
                stateSetter={
                  setShowModal as Dispatch<SetStateAction<ShowModal>>
                }
              />
            </Col>
            <Col lg={1} />
            <Col lg={2} id='desktop-explainer-btn'>
              {isDesktop &&
                !loggedIn &&
                !serverHasError &&
                splash === 'Tour' && (
                  <GenericBtn
                    onPress={() =>
                      (
                        setShowModal as Dispatch<
                          React.SetStateAction<ShowModal>
                        >
                      )((s) => ({ ...s, explainer: true }))
                    }
                    label={APP.EXPLAINER_BTN_LABEL}
                    size={'sm'}
                  />
                )}
            </Col>
          </>
        )}
        <Col xs={12} lg={4}>
          <Row>
            {(isDesktop ||
              (!isDesktop &&
                (loggedIn ||
                  (route.name === 'main' && splash === 'Tour')))) && (
              <Col xs={12} lg={8}>
                <Brand
                  {...props}
                  splash={splash}
                  isMobile={isMobile}
                  isDesktop={isDesktop}
                  setShowSideNav={setShowSideNav}
                  setShowAlert={
                    setShowAlert as Dispatch<SetStateAction<ShowAlert>>
                  }
                />
              </Col>
            )}
            {isDesktop && !serverHasError && (
              <User
                setShowModal={
                  setShowModal as Dispatch<SetStateAction<ShowModal>>
                }
                setShowAlert={
                  setShowAlert as Dispatch<SetStateAction<ShowAlert>>
                }
                setCredentialsPath={
                  setCredentialsPath as Dispatch<
                    SetStateAction<CredentialsPath>
                  >
                }
                loggedIn={loggedIn as boolean}
                handleLogOut={handleLogOut}
                isMobile={isMobile}
                splash={splash}
                route={route}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Navbar>
  );
};

export default React.memo(Navigation);
