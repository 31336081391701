import React, {
  useMemo,
  Dispatch,
  useCallback,
  useTransition,
  SetStateAction,
} from 'react';
import {
  AlertJoinup,
  AlertDeleteAcct,
  AlertLoginLogout,
  AlertResetLinkSent,
  AlertAcctActivated,
} from '@Components/alerts';
import { handleSecret } from './fn';
import { SPLASH } from '@CONSTANTS';
import { ShowAlert } from '@Interfaces';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Image from 'react-bootstrap/esm/Image';
import Stack from 'react-bootstrap/esm/Stack';
import Logo from '@Images/logo/cable-nav.png';
import { createBrowserHistory } from 'history';
import { APP, ALERT_TIMEOUT } from '@CONSTANTS';
import MP4_VIDEO from '../../media/explainer.mp4';
import WEBM_VIDEO from '../../media/explainer.webm';
import Container from 'react-bootstrap/esm/Container';
import { Props, SplashPath, UserEvent } from '@Types';
import { Logio, VideoPlayer } from '@Components/interactive';
import { GenericBtn, MobileFormBtn } from '@Components/buttons';
import './style.css';

const Splash = ({
  safelyHandleLastSplash,
  accountActivated,
  credentialsPath,
  setShowAlert,
  isDesktop,
  setSplash,
  showAlert,
  isMobile,
  loggedIn = false,
  splash,
  route,
  ...props
}: Props) => {
  // const history = createBrowserHistory();

  const [isPendingValidation, startValidationTransition] = useTransition();

  const handleOnPressMobilePathBtn = useCallback(
    (e: UserEvent) => {
      setShowAlert((s) => ({ ...s, activate: false, logout: false })); // alert overlaps and covers userform
      startValidationTransition(() => {
        (setSplash as (path: SplashPath) => void)(
          (e.target as HTMLInputElement).textContent as SplashPath
        );
        //   history.push('/', {
        //     splash: (e.target as HTMLInputElement).textContent as string,
        //   });
      });
    },
    [
      // history,
      setSplash,
      setShowAlert,
    ]
  );

  // useEffect(
  //   () => (safelyHandleLastSplash as () => void)(),
  //   [history, safelyHandleLastSplash]
  // );

  const alertArr = useMemo(() => {
    return [
      { element: AlertJoinup, key: 'join' },
      { element: AlertDeleteAcct, key: 'delete' },
      { element: AlertLoginLogout, key: 'login' },
      { element: AlertResetLinkSent, key: 'reset' },
      { element: AlertAcctActivated, key: 'activate' },
    ];
  }, []);

  const MOBILE_BTS = APP.SPLASH_PATHS.slice(0, 2);

  return (
    <>
      {alertArr.map((alert) => {
        const Alert = alert.element,
          alertKey = 'alert-' + alert.key;
        return (
          <Alert
            timeout={(ALERT_TIMEOUT as any)[alert.key] as number}
            show={showAlert as ShowAlert & true}
            setShow={setShowAlert}
            loggedIn={loggedIn}
            key={alertKey}
          />
        );
      })}
      <Container id='splash' className='p-4 p-xxl-0 pt-lg-3 pt-xxl-0'>
        <h1 className='mission-statement pt-xs-0 mt-xs-0'>
          {SPLASH.SLOGAN}
        </h1>
        <Row className='cta-row'>
          <div className='splash-content'>
            {splash === '' && (
              <Col lg={6} className='explainer'>
                <Stack direction='vertical'>
                  {/* Watch video btn */}
                  {!isDesktop && isMobile && (
                    <GenericBtn
                      onPress={() => {
                        (
                          setSplash as Dispatch<SetStateAction<SplashPath>>
                        )('Explainer');
                      }}
                      label={SPLASH.COPY.video.open}
                      size={'sm'}
                    />
                  )}

                  {/* Splash written copy */}
                  <p className='intro'>
                    <span className='powerback'>POWERBACK</span>
                    {SPLASH.COPY.intro} {/* Tour link >> */}
                    <span
                      className={'natural-link'}
                      onClick={() =>
                        (
                          setSplash as Dispatch<SetStateAction<SplashPath>>
                        )('Tour')
                      }>
                      {SPLASH.COPY.tour}
                    </span>
                  </p>
                </Stack>
              </Col>
            )}
            {/* Video player */}
            {(isDesktop || (splash === 'Explainer' && isMobile)) && (
              <Col lg={'auto'} className={'mb-3'}>
                <VideoPlayer
                  setShowAlert={setShowAlert}
                  altVideoPath={MP4_VIDEO}
                  videoPath={WEBM_VIDEO}
                  isDesktop={isDesktop}
                  isMobile={isMobile}
                  splash={splash}
                />
              </Col>
            )}
            {/* Join Now/Sign In buttons on mobile UI */}
            {!isDesktop && isMobile && (
              <Col className='mobile-splash-btns'>
                {splash === '' && (
                  <Stack direction='horizontal' gap={3}>
                    {(MOBILE_BTS as string[]).map((splash) => (
                      <MobileFormBtn
                        onPress={handleOnPressMobilePathBtn}
                        key={'mobile-btn-' + splash}
                        label={splash}
                        size={'lg'}
                      />
                    ))}
                  </Stack>
                )}
                {splash === 'Explainer' && (
                  <GenericBtn
                    onPress={() =>
                      (setSplash as Dispatch<SetStateAction<SplashPath>>)(
                        ''
                      )
                    }
                    label={SPLASH.COPY.video.close}
                    size={'sm'}
                  />
                )}
              </Col>
            )}
            {/* Join Now/Sign In forms on mobile UI */}
            {!isDesktop &&
              isMobile &&
              (splash === 'Join Now' || splash === 'Sign In') && (
                <Logio
                  {...props}
                  safelyHandleLastSplash={safelyHandleLastSplash}
                  isPendingValidation={isPendingValidation}
                  credentialsPath={credentialsPath}
                  setShowAlert={setShowAlert}
                  isDesktop={isDesktop}
                  showAlert={showAlert}
                  setSplash={setSplash}
                  isMobile={isMobile}
                  loggedIn={loggedIn}
                  BTNS={MOBILE_BTS}
                  splash={splash}
                  route={route}
                />
              )}
          </div>

          {/* Slogan and logo */}
          {(isDesktop ||
            (splash !== 'Join Now' &&
              splash !== 'Sign In' &&
              isMobile)) && (
            <Col lg={3} className='cta-w-icon mt-4'>
              {SPLASH.COPY.cta}
              <Image
                onClick={() =>
                  handleSecret(
                    SPLASH.EASTER_EGG_URI,
                    SPLASH.EXT_URI_SETTINGS
                  )
                }
                className={'splash-icon pt-1'}
                alt={'Powerback "cable" icon'}
                width={SPLASH.IMG_HEIGHT * 0.905}
                height={SPLASH.IMG_HEIGHT}
                src={Logo}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default React.memo(Splash);
