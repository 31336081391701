import { loadUser } from '@Utils';
import API from '@API';

export const login = ({
  setCookie,
  setLoggedIn,
  setUserData,
  swapToError,
  setShowAlert,
  setShowModal,
  userEntryForm,
  setUserEntryForm,
  switchToErrorScreen,
  setUserFormValidated,
  stopLoggingInSpinner,
}) => {
  const u = userEntryForm;
  API.login({
    ...u,
  })
    .then((res) => {
      const { data: user } = res;
      loadUser(
        user,
        setCookie,
        setLoggedIn,
        setUserData,
        setShowAlert,
        setShowModal,
        setUserEntryForm,
        switchToErrorScreen,
        setUserFormValidated
      );
    })
    .catch((err) => {
      console.error(err);
      const status =
        err && err.response && err.response.status
          ? err.response.status
          : '';
      setUserEntryForm({
        ...u,
        err: status,
      });
      swapToError(status);
      stopLoggingInSpinner();
    });
};
