import React, {
  useMemo,
  Dispatch,
  ChangeEvent,
  useCallback,
  SetStateAction,
} from 'react';
import { Messages, UserData, ShowModal } from '@Interfaces';
import FormControl from 'react-bootstrap/esm/FormControl';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import accounting from 'accounting';
import { AMOUNT } from '@CONSTANTS';
import { Props } from '@Types';
import './style.css';

const INPUT_LIMIT = 9999,
  SIGFIGS = 8;

type DonationInputProps = {
  setModalMessage?: Dispatch<SetStateAction<string>>;
  setAmount: Dispatch<SetStateAction<number>>;
  remainingDonationLimit?: number;
  formValue: number | '';
  amount: number | '';
  AMOUNT?: Messages;
};

const DonationInput = ({
  remainingDonationLimit,
  setModalMessage,
  setShowModal,
  formValue,
  setAmount,
  userData,
  amount,
  isTip,
}: Props & DonationInputProps) => {
  const calcValue = useCallback((e: ChangeEvent) => {
    let v = Number((e.target as HTMLInputElement).value);
    if (v < 0) v = 0;
    if (v < 1 && v > 0) v = 1;
    if (new RegExp('^\\d+\\.\\d{3,}$').test(String(v)))
      v = Number(
        Number(String(v).slice(0, String(v).length - 1)).toFixed(2)
      );
    // max 6-figures w/o decimals
    if (String(v).length > SIGFIGS || v > INPUT_LIMIT)
      v = Number(String(v).slice(0, String(v).length - 1));
    (e.target as HTMLInputElement).value = String(v);
    return v;
  }, []);

  const handleChange = useCallback(
      (e: ChangeEvent) => {
        let v = calcValue(e);
        if (
          remainingDonationLimit &&
          v > (remainingDonationLimit as number) &&
          userData &&
          (userData as UserData).id !== ''
        ) {
          if ((remainingDonationLimit as number) <= 0) {
            (setModalMessage as Dispatch<React.SetStateAction<string>>)(
              (AMOUNT as Messages).reached
            );
          } else {
            (setModalMessage as Dispatch<React.SetStateAction<string>>)(
              (AMOUNT as Messages).exceeds[0] +
                accounting.formatMoney(remainingDonationLimit as number) +
                (AMOUNT as Messages).exceeds[1]
            );
          }
          setAmount(0);
          (setShowModal as Dispatch<SetStateAction<ShowModal>>)((s) => ({
            ...s,
            limit: true,
          }));
        } else setAmount(Number(v));
      },
      [
        remainingDonationLimit,
        setModalMessage,
        setShowModal,
        setAmount,
        calcValue,
        userData,
      ]
    ),
    makePlaceholder = useMemo(() => {
      return String((isTip && 0) || (amount && amount));
    }, [isTip, amount]);

  return (
    <InputGroup id='donation-input' className='donation-input'>
      <InputGroup.Text>$</InputGroup.Text>
      <FormControl
        aria-label={'Input for donation or tip amount in dollars'}
        aria-describedby={'donation-input'}
        className={'donation-input-field'}
        placeholder={makePlaceholder}
        name={'donation-input'}
        onChange={handleChange}
        onKeyDown={() => {
          return true;
        }}
        value={formValue}
        type={'number'}
      />
    </InputGroup>
  );
};

export default React.memo(DonationInput);
