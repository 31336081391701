import React, {
  useMemo,
  useState,
  useReducer,
  useCallback,
  ReactEventHandler,
} from 'react';
import Image from 'react-bootstrap/esm/Image';
import { BACKUP_IMG } from '@CONSTANTS';
import API from '@API';
import './style.css';

const Headshot = ({
  name,
  cls,
  src,
  id,
}: {
  id: string;
  src: string;
  cls: string;
  name: string;
}) => {
  const [dynamicClass, setDynamicClass] = useState<string>();

  // highlight selected image
  const calcDynamicClass = useCallback(() => {
    if (src === id) {
      if (cls.length) {
        return cls;
      } else {
        return 'pol-headshot-selected';
      }
    } else if (src === id) {
      return `${cls}-nil`;
    } else if (cls === 'checkout-headshot') return cls;
    else return 'pol-selector-img';
  }, [id, src, cls]);

  const [prevId, setPrevId] = useState<string>();
  if (prevId !== id) {
    setPrevId(id);
    setDynamicClass(calcDynamicClass());
  }

  const [backup, setBackup] = useReducer((state) => {
    return (state = true);
  }, false);

  const imgsrc = useMemo(() => {
    return backup ? BACKUP_IMG + src + '.jpg' : `../pfp/${src}.webp`;
  }, [src, backup]);

  const handleError = useCallback<ReactEventHandler<HTMLImageElement>>(
    (e) => {
      setBackup();
      API.notifyImgErr(
        ((e.target as HTMLImageElement).attributes as unknown as any).src
          .value as unknown as string
      ).catch((err) => console.error(err));
    },
    []
  );

  return (
    <div className='headshot-container'>
      <Image
        alt={`The official Congressional headshot of ${name}.`}
        aria-label={'Politician profile picture'}
        className={dynamicClass + ' headshot'}
        title={`politician selection ${name}`}
        onError={handleError}
        loading={'lazy'}
        src={imgsrc}
      />
    </div>
  );
};

export default React.memo(Headshot);
