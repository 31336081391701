import { INIT } from '@CONSTANTS';
import API from '@API';

export const logOut = ({
  setSplash,
  setTabKey,
  setDonation,
  setLoggedIn,
  setUserData,
  setShowAlert,
  removeCookie,
  setSelectedPol,
  setShowSideNav,
  switchToErrorScreen,
  stopLoggingInSpinner,
}) => {
  API.logout() // api call -- logout
    .then(() => {
      stopLoggingInSpinner();
      setShowAlert((s) => ({
        ...s,
        login: false,
        logout: true,
        activate: false,
      })); // show alert
      setShowSideNav(false); // close sidenav
      setSplash(''); // mobile only; revert back to formless home screen
      setLoggedIn(); // log user out
      setUserData(INIT.credentials); // initialize user object
      setSelectedPol(null); // clear pol selection
      setDonation(0); // clear donation
      setTabKey('pol-donation'); // reset screen tabs
      removeCookie('refreshToken', {
        path: '/',
        // secure: false,
        // domain: 'localhost',
        // sameSite: 'strict',
        // partitioned: false,
        // httpOnly: true,
      });
    })
    .catch((err) => {
      if (err.response) switchToErrorScreen(err.response);
    });
};
