import React, {
  useMemo,
  Dispatch,
  useCallback,
  SetStateAction,
} from 'react';
import { StyledModal } from '@Components/modals';
import { CredentialsPath, Props } from '@Types';
import { Logio } from '@Components/interactive';
import { ShowModal } from '@Interfaces';
import { APP } from '@CONSTANTS';
import './style.css';

const CredentialsModal = ({
  loggedInBeforePaymentGuard,
  setForgotPwOverlayTarget,
  forgotPwOverlayTarget,
  setUserFormValidated,
  setCredentialsPath,
  credentialsPath,
  setShowOverlay,
  setShowModal,
  setSplash,
  showModal,
  loggedIn,
  route,
  ...props
}: Props) => {
  const onExited = useCallback(() => {
    (setUserFormValidated as Dispatch<SetStateAction<boolean>>)(false);
    (setCredentialsPath as Dispatch<SetStateAction<CredentialsPath>>)('');
  }, [setUserFormValidated, setCredentialsPath]);

  const isJoinNow = useMemo(() => {
      return (credentialsPath as CredentialsPath) === 'Join Now';
    }, [credentialsPath]),
    heading = useMemo(() => {
      return (
        <>
          {isJoinNow ? 'Create your new' : 'Sign into your'}{' '}
          <span className='powerback'>POWERBACK</span> account
        </>
      );
    }, [isJoinNow]);

  const startRequirement = useMemo(() => {
    return `You ${
      APP.CREDENTIALS.TO_START[+isJoinNow]
    } to start your Celebration!`;
  }, [isJoinNow]);

  return (
    <StyledModal
      setShowModal={setShowModal as Dispatch<SetStateAction<ShowModal>>}
      showModal={showModal as ShowModal}
      type={'credentials'}
      onExited={onExited}
      closeButton={true}
      heading={heading}
      body={
        <>
          {!loggedIn && loggedInBeforePaymentGuard && (
            <p>{startRequirement}</p>
          )}
          <Logio
            {...props}
            setForgotPwOverlayTarget={setForgotPwOverlayTarget}
            forgotPwOverlayTarget={forgotPwOverlayTarget}
            setCredentialsPath={setCredentialsPath}
            credentialsPath={credentialsPath}
            setShowOverlay={setShowOverlay}
            setShowModal={setShowModal}
            setSplash={setSplash}
            showModal={showModal}
            route={route}
          />
        </>
      }
      // no footer
    />
  );
};

export default React.memo(CredentialsModal);
