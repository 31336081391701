import React, {
  Dispatch,
  MouseEvent,
  KeyboardEvent,
  SetStateAction,
} from 'react';
import { APP, GIT_REPO } from '@CONSTANTS';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/esm/Nav';
import Row from 'react-bootstrap/esm/Row';
import Tab from 'react-bootstrap/esm/Tab';
import { ShowModal } from '@Interfaces';
import { SplashPath } from '@Types';
import SideLink from './Link';
import './style.css';

type Props = {
  handleLogOut: (e: KeyboardEvent | MouseEvent) => void;
  openModal: (modal: keyof ShowModal | string) => void;
  setSplash: Dispatch<SetStateAction<SplashPath>>;
  loggedIn: boolean;
  username: string;
};

const BottomPanel = ({
  handleLogOut,
  setSplash,
  loggedIn,
  username,
  openModal,
}: Props) => {
  return (
    <Tab.Container id='side-nav-tabs'>
      <Row
        id='account-slide-row'
        className={'side-menu-profile-row flex-row'}
        style={{ fontFamily: 'Red Hat Text' }}>
        <Col>
          <Nav className='flex-column pt-2'>
            {APP.NAV.MODALS.map((modal) => (
              <SideLink
                key={modal + '-sidelink'}
                modal={modal as keyof ShowModal & string}
                openModal={openModal}
              />
            ))}

            {(loggedIn && (
              <>
                <SideLink
                  icon={'person-circle'}
                  openModal={openModal}
                  setSplash={setSplash}
                  modal={'account'}
                  cls={'pt-5 pb-3'}
                />
                <SideLink
                  icon={'box-arrow-left'}
                  handler={handleLogOut}
                  setSplash={setSplash}
                  label={'Sign out'}
                  cls={'py-4'}
                />
                <span className='signedin-info'>
                  {'Signed in as ' + username}
                </span>
              </>
            )) || (
              <>
                {APP.NAV.SIDENAV_TOUR.map((o) => (
                  <SideLink
                    key={o.label + '-sidelink'}
                    setSplash={setSplash}
                    label={o.label as SplashPath}
                    icon={o.icon}
                    cls={o.cls}
                  />
                ))}
              </>
            )}
            <SideLink
              cls={'pt-5 mt-5 fs-6'}
              label={'Contribute'}
              href={GIT_REPO}
              icon={'github'}
            />
          </Nav>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default React.memo(BottomPanel);
