const COSPONSORS = [
    "J000298",
    "D000624",
    "A000370",
    "B001318",
    "B001300",
    "B001292",
    "B000574",
    "B001278",
    "B001223",
    "B001296",
    "B001313",
    "B001224",
    "C001112",
    "C001097",
    "C001072",
    "C001125",
    "C001090",
    "C001131",
    "C001127",
    "C001080",
    "C001084",
    "C001067",
    "C001061",
    "C001068",
    "C001130",
    "D000096",
    "D000197",
    "D000530",
    "D000623",
    "D000399",
    "E000299",
    "E000297",
    "F000477",
    "F000462",
    "F000476",
    "G000559",
    "G000598",
    "G000586",
    "G000599",
    "G000585",
    "G000553",
    "G000551",
    "H001090",
    "H001081",
    "H001038",
    "H001094",
    "H001068",
    "I000058",
    "J000309",
    "J000032",
    "J000305",
    "J000288",
    "K000400",
    "K000375",
    "K000385",
    "K000389",
    "L000551",
    "L000602",
    "L000273",
    "L000593",
    "L000582",
    "M001143",
    "M001220",
    "M000312",
    "M001137",
    "M001188",
    "M000687",
    "M001225",
    "N000002",
    "N000179",
    "N000191",
    "N000147",
    "O000172",
    "O000173",
    "P000034",
    "P000613",
    "P000604",
    "P000597",
    "P000607",
    "P000618",
    "P000617",
    "Q000023",
    "R000617",
    "R000606",
    "S001177",
    "S001226",
    "S001156",
    "S001168",
    "S001145",
    "S001150",
    "S000185",
    "S000344",
    "S000510",
    "S001218",
    "S001193",
    "T000472",
    "T000488",
    "T000460",
    "T000193",
    "T000468",
    "T000481",
    "T000487",
    "T000469",
    "T000486",
    "T000482",
    "V000130",
    "V000081",
    "W000187",
    "W000822",
    "W000826",
    "W000788",
    "W000808",
    "L000397"
];

export default COSPONSORS;