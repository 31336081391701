module.exports = [
  'Abortion',
  'Academic performance and assessments',
  'Accidents',
  'Accounting and auditing',
  'Administrative law and regulatory procedures',
  'Administrative remedies',
  'Adoption and foster care',
  'Adult day care',
  'Adult education and literacy',
  'Advanced technology and technological innovations',
  'Advisory bodies',
  'Age discrimination',
  'Aging',
  'Agricultural conservation and pollution',
  'Agricultural education',
  'Agricultural equipment and machinery',
  'Agricultural insurance',
  'Agricultural marketing and promotion',
  'Agricultural practices and innovations',
  'Agricultural prices, subsidies, credit',
  'Agricultural research',
  'Agricultural trade',
  'Air quality',
  'Alaska Natives and Hawaiians',
  'Alcoholic beverages',
  'Allergies',
  'Alliances',
  'Allied health services',
  'Alternative and renewable resources',
  'Alternative dispute resolution, mediation, arbitration',
  'Alternative treatments',
  'Animal and plant health',
  'Animal protection and human-animal relationships',
  'Appropriations',
  'Aquaculture',
  'Aquatic ecology',
  'Arab-Israeli relations',
  'Archaeology and anthropology',
  'Architecture',
  'Arctic and polar regions',
  'Area studies and international education',
  'Arms control and nonproliferation',
  'Art, artists, authorship',
  'Assault and harassment offenses',
  'Astronomy',
  'Athletes',
  'Atmospheric science and weather',
  'Aviation and airports',
  'Bank accounts, deposits, capital',
  'Banking and financial institutions regulation',
  'Bankruptcy',
  'Behavioral sciences',
  'Biological and life sciences',
  'Birds',
  'Birth defects',
  'Blood and blood diseases',
  'Books and print media',
  'Border security and unlawful immigration',
  'Broadcasting, cable, digital technologies',
  'Budget deficits and national debt',
  'Budget process',
  'Building construction',
  'Business education',
  'Business ethics',
  'Business expenses',
  'Business investment and capital',
  'Business records',
  'Buy American requirements',
  'Cancer',
  'Capital gains tax',
  'Cardiovascular and respiratory health',
  'Cell biology and embryology',
  'Cemeteries and funerals',
  'Census and government statistics',
  'Charitable contributions',
  'Chemical and biological weapons',
  'Chemistry',
  'Child care and development',
  'Child health',
  'Child safety and welfare',
  'Citizenship and naturalization',
  'Civics education',
  'Civil actions and liability',
  'Civil disturbances',
  'Climate change and greenhouse gases',
  'Coal',
  'Coast guard',
  'Collective security',
  'Commemorative events and holidays',
  'Commodities markets',
  'Community life and organization',
  'Commuting',
  'Competition and antitrust',
  'Competitiveness, trade promotion, trade deficits',
  'Comprehensive health care',
  'Computer security and identity theft',
  'Computers and information technology',
  'Conflicts and wars',
  'Congressional agencies',
  'Congressional committees',
  'Congressional districts and representation',
  'Congressional elections',
  'Congressional leadership',
  'Congressional officers and employees',
  'Congressional operations and organization',
  'Congressional oversight',
  'Congressional tributes',
  'Congressional-executive branch relations',
  'Constitution and constitutional amendments',
  'Consumer affairs',
  'Consumer credit',
  'Contracts and agency',
  'Cooperative and condominium housing',
  'Corporate finance and management',
  'Correctional facilities and imprisonment',
  'Cosmetics and personal care',
  'Credit and credit markets',
  'Crime prevention',
  'Crime victims',
  'Crimes against animals and natural resources',
  'Crimes against children',
  'Crimes against property',
  'Crimes against women',
  'Criminal investigation, prosecution, interrogation',
  'Criminal justice information and records',
  'Criminal procedure and sentencing',
  'Cultural exchanges and relations',
  'Currency',
  'Customs enforcement',
  'Dams and canals',
  'Debt collection',
  'Defense spending',
  'Dental care',
  'Detention of persons',
  'Digestive and metabolic diseases',
  'Digital media',
  'Diplomacy, foreign officials, Americans abroad',
  'Disability and health-based discrimination',
  'Disability and paralysis',
  'Disability assistance',
  'Disaster relief and insurance',
  'Domestic violence and child abuse',
  'Drug safety, medical device, and laboratory regulation',
  'Drug therapy',
  'Drug trafficking and controlled substances',
  'Drug, alcohol, tobacco use',
  'Due process and equal protection',
  'Earth sciences',
  'Ecology',
  'Economic development',
  'Economic performance and conditions',
  'Economic theory',
  'Education of the disadvantaged',
  'Education programs funding',
  'Educational facilities and institutions',
  'Educational guidance',
  'Educational technology and distance education',
  'Elections, voting, political campaign regulation',
  'Electric power generation and transmission',
  'Elementary and secondary education',
  'Emergency communications systems',
  'Emergency medical services and trauma care',
  'Emergency planning and evacuation',
  'Employee benefits and pensions',
  'Employee hiring',
  'Employee leave',
  'Employee performance',
  'Employment and training programs',
  'Employment discrimination and employee rights',
  'Employment taxes',
  'Endangered and threatened species',
  'Energy assistance for the poor and aged',
  'Energy efficiency and conservation',
  'Energy prices',
  'Energy research',
  'Energy revenues and royalties',
  'Energy storage, supplies, demand',
  'Environmental assessment, monitoring, research',
  'Environmental education',
  'Environmental health',
  'Environmental regulatory procedures',
  'Environmental technology',
  'Espionage and treason',
  'Ethnic studies',
  'Evidence and witnesses',
  'Executive agency funding and structure',
  'Family planning and birth control',
  'Family relationships',
  'Family services',
  'Farmland',
  'Federal appellate courts',
  'Federal district courts',
  'Federal officials',
  'Federal preemption',
  'Federal-Indian relations',
  'Federally chartered organizations',
  'Financial crises and stabilization',
  'Financial literacy',
  'Financial services and investments',
  'Firearms and explosives',
  'Fires',
  'First Amendment rights',
  'First responders and emergency personnel',
  'Fishes',
  'Floods and storm protection',
  'Food assistance and relief',
  'Food industry and services',
  'Food supply, safety, and labeling',
  'Foreign aid and international relief',
  'Foreign and international banking',
  'Foreign and international corporations',
  'Foreign labor',
  'Foreign language and bilingual programs',
  'Foreign loans and debt',
  'Foreign property',
  'Forests, forestry, trees',
  'Fraud offenses and financial crimes',
  'Free trade and trade barriers',
  'Freedom of information',
  'Fruit and vegetables',
  'Gambling',
  'Games and hobbies',
  'General Native American affairs matters',
  'General agriculture matters',
  'General business and commerce matters',
  'General education matters',
  'General energy matters',
  'General foreign operations matters',
  'General health and health care finance matters',
  'General public lands matters',
  'General science and technology matters',
  'General taxation matters',
  'Genetics',
  'Geography and mapping',
  'Government buildings, facilities, and property',
  'Government corporations and government-sponsored enterprises',
  'Government employee pay, benefits, personnel management',
  'Government ethics and transparency, public corruption',
  'Government information and archives',
  'Government lending and loan guarantees',
  'Government liability',
  'Government studies and investigations',
  'Government trust funds',
  'Grain',
  'HIV/AIDS',
  'Hate crimes',
  'Hazardous wastes and toxic substances',
  'Health care costs and insurance',
  'Health care coverage and access',
  'Health care quality',
  'Health facilities and institutions',
  'Health information and medical records',
  'Health personnel',
  'Health programs administration and funding',
  'Health promotion and preventive care',
  'Health technology, devices, supplies',
  'Hearing, speech, and vision care',
  'Hereditary and development disorders',
  'Higher education',
  'Historic sites and heritage areas',
  'Historical and cultural resources',
  'Home and outpatient care',
  'Homeland security',
  'Homelessness and emergency shelter',
  'Horticulture and plants',
  'Hospital care',
  'House of Representatives',
  'Housing and community development funding',
  'Housing discrimination',
  'Housing finance and home ownership',
  'Housing for the elderly and disabled',
  'Housing industry and standards',
  'Housing supply and affordability',
  'Human rights',
  'Human trafficking',
  'Humanities programs funding',
  'Hunting and fishing',
  'Hybrid, electric, and advanced technology vehicles',
  'Hydrology and hydrography',
  'Immigrant health and welfare',
  'Immigration status and procedures',
  'Immunology and vaccination',
  'Income tax credits',
  'Income tax deductions',
  'Income tax deferral',
  'Income tax exclusion',
  'Income tax rates',
  'Indian claims',
  'Indian lands and resources rights',
  'Indian social and development programs',
  'Industrial facilities',
  'Industrial policy and productivity',
  'Infectious and parasitic diseases',
  'Inflation and prices',
  'Infrastructure development',
  'Insects',
  'Insurance industry and regulation',
  'Intellectual property',
  'Intelligence activities, surveillance, classified information',
  'Interest, dividends, interest rates',
  'Intergovernmental relations',
  'International exchange and broadcasting',
  'International law and treaties',
  'International monetary system and foreign exchange',
  'International organizations and cooperation',
  'International scientific cooperation',
  'Internet, web applications, social media',
  'Judges',
  'Judicial procedure and administration',
  'Judicial review and appeals',
  'Jurisdiction and venue',
  'Juvenile crime and gang violence',
  'Labor market',
  'Labor standards',
  'Labor-management relations',
  'Lakes and rivers',
  'Land transfers',
  'Land use and conservation',
  'Landlord and tenant',
  'Language arts',
  'Law enforcement administration and funding',
  'Law enforcement officers',
  'Lawyers and legal services',
  'Lease and rental services',
  'Legal fees and court costs',
  'Legislative rules and procedure',
  'Libraries and archives',
  'Licensing and registrations',
  'Life, casualty, property insurance',
  'Lighting, heating, cooling',
  'Literature',
  'Livestock',
  'Long-term, rehabilitative, and terminal care',
  'Low- and moderate-income housing',
  'Mammals',
  'Manufacturing',
  'Marine and coastal resources, fisheries',
  'Marine and inland water transportation',
  'Marine pollution',
  'Marketing and advertising',
  'Marriage and family status',
  'Materials',
  'Meat',
  'Medicaid',
  'Medical education',
  'Medical ethics',
  'Medical research',
  'Medical tests and diagnostic methods',
  'Medicare',
  'Members of Congress',
  'Mental health',
  'Metals',
  'Migrant, seasonal, agricultural labor',
  'Military assistance, sales, and agreements',
  'Military civil functions',
  'Military command and structure',
  'Military education and training',
  'Military facilities and property',
  'Military history',
  'Military law',
  'Military medicine',
  'Military operations and strategy',
  'Military personnel and dependents',
  'Military procurement, research, weapons development',
  'Military readiness',
  'Militias and paramilitary groups',
  'Mining',
  'Minority and disadvantaged businesses',
  'Minority education',
  'Minority employment',
  'Minority health',
  'Missing persons',
  'Monetary policy',
  'Monuments and memorials',
  'Motor carriers',
  'Motor fuels',
  'Motor vehicles',
  'Multilateral development programs',
  'Musculoskeletal and skin diseases',
  'Museums, exhibitions, cultural centers',
  'Music',
  'Named Entities - Geographic',
  'Named Entities - Organizations',
  'National Guard and reserves',
  'National and community service',
  'National symbols',
  'Natural disasters',
  'Navigation, waterways, harbors',
  'Neurological disorders',
  'News media and reporting',
  'Noise pollution',
  'Normal trade relations, most-favored-nation treatment',
  'Nuclear power',
  'Nuclear weapons',
  'Nursing',
  'Nutrition and diet',
  'Oil and gas',
  'Olympic games',
  'Organ and tissue donation and transplantation',
  'Organized crime',
  'Outdoor recreation',
  'Palestinians',
  'Parks, recreation areas, trails',
  'Pedestrians and bicycling',
  'Performance measurement',
  'Performing arts',
  'Personnel records',
  'Pest management',
  'Photography and imaging',
  'Physical fitness and lifestyle',
  'Pipelines',
  'Policy sciences',
  'Political advertising',
  'Political movements and philosophies',
  'Political parties and affiliation',
  'Political representation',
  'Pollution liability',
  'Pornography',
  'Postal service',
  'Poverty and welfare assistance',
  'Preschool education',
  'Prescription drugs',
  'Presidential administrations',
  'Presidents and presidential powers, Vice Presidents',
  'Private Legislation',
  'Product development and innovation',
  'Product safety and quality',
  'Professional sports',
  'Property rights',
  'Property tax',
  'Protection of officials',
  'Protest and dissent',
  'Public contracts and procurement',
  'Public housing',
  'Public participation and lobbying',
  'Public transit',
  'Public utilities and utility rates',
  'Public-private cooperation',
  'Racial and ethnic relations',
  'Radiation',
  'Radio spectrum allocation',
  'Radioactive wastes and releases',
  'Railroads',
  'Real estate business',
  'Reconstruction and stabilization',
  'Refugees, asylum, displaced persons',
  'Regional and metropolitan planning',
  'Religion',
  'Reptiles',
  'Research administration and funding',
  'Research and development',
  'Research ethics',
  'Residential rehabilitation and home repair',
  'Retail and wholesale trades',
  'Right of privacy',
  'Roads and highways',
  'Rule of law and government transparency',
  'Rural conditions and development',
  'Sales and excise taxes',
  'Sanctions',
  'School administration',
  'School athletics',
  'Science and engineering education',
  'Scientific communication',
  'Seafood',
  'Seashores and lakeshores',
  'Securities',
  'Self-employed',
  'Senate',
  'Separation, divorce, custody, support',
  'Service animals',
  'Service industries',
  'Sex and reproductive health',
  'Sex offenses',
  'Sex, gender, sexual orientation discrimination',
  'Sexually transmitted diseases',
  'Small business',
  'Small towns',
  'Smuggling and trafficking',
  'Social security and elderly assistance',
  'Social work, volunteer service, charitable organizations',
  'Soil pollution',
  'Solid waste and recycling',
  'Sound recording',
  'Sovereignty, recognition, national governance and status',
  'Space flight and exploration',
  'Spacecraft and satellites',
  'Special education',
  'Specialized courts',
  'Sports and recreation facilities',
  'State and local courts',
  'State and local finance',
  'State and local government operations',
  'State and local taxation',
  'Strategic materials and reserves',
  'Student aid and college costs',
  'Student records',
  'Subversive activities',
  'Supreme Court',
  'Surgery and anesthesia',
  'Tariffs',
  'Tax administration and collection, taxpayers',
  'Tax reform and tax simplification',
  'Tax treatment of families',
  'Tax-exempt organizations',
  'Taxation of foreign income',
  'Teaching, teachers, curricula',
  'Technology assessment',
  'Technology transfer and commercialization',
  'Teenage pregnancy',
  'Telecommunication rates and fees',
  'Telephone and wireless communication',
  'Television and film',
  'Temporary and part-time employment',
  'Terrorism',
  'Time and calendar',
  'Trade adjustment assistance',
  'Trade agreements and negotiations',
  'Trade restrictions',
  'Trade secrets and economic espionage',
  'Transfer and inheritance taxes',
  'Transportation costs',
  'Transportation employees',
  'Transportation programs funding',
  'Transportation safety and security',
  'Travel and tourism',
  'U.S. Capitol',
  'U.S. and foreign investments',
  'U.S. history',
  'U.S. territories and protectorates',
  'Unemployment',
  'Urban and suburban affairs and development',
  'User charges and fees',
  "Veterans' education, employment, rehabilitation",
  "Veterans' loans, housing, homeless programs",
  "Veterans' medical care",
  "Veterans' organizations and recognition",
  "Veterans' pensions and compensation",
  'Veterinary medicine and animal diseases',
  'Violent crime',
  'Visas and passports',
  'Vocational and technical education',
  'Voting rights',
  'Wages and earnings',
  'War and emergency powers',
  'War crimes, genocide, crimes against humanity',
  'Water quality',
  'Water resources funding',
  'Water storage',
  'Water use and supply',
  'Watersheds',
  'Wetlands',
  'White-collar crime',
  'Wilderness and natural areas, wildlife refuges, wild rivers, habitats',
  'Wildlife conservation and habitat protection',
  'Women in business',
  "Women's education",
  "Women's employment",
  "Women's health",
  "Women's rights",
  'Worker safety and health',
  'World health',
  'World history',
  'Youth employment and child labor',
];
