import {
  useMemo,
  Dispatch,
  useReducer,
  useCallback,
  SetStateAction,
} from 'react';
import { APP } from '@CONSTANTS';
import { SplashPath } from '@Types';

interface Handlers {
  setSplash: Dispatch<SetStateAction<SplashPath>>;
}

export default function useSplash(): [string, Handlers] {
  const reducer = useCallback((state: string, path: SplashPath) => {
      switch (APP.SPLASH_PATHS[APP.SPLASH_PATHS.indexOf(path)]) {
        case APP.SPLASH_PATHS[APP.SPLASH_PATHS.indexOf(path)]:
          return (state =
            APP.SPLASH_PATHS[APP.SPLASH_PATHS.indexOf(path)]);
        default:
          throw new Error();
      }
    }, []),
    [state, dispatch] = useReducer(reducer, ''),
    handlers = useMemo<Handlers>(
      () => ({
        setSplash: (path) => dispatch(path as SplashPath),
      }),
      []
    );
  return [state, handlers];
}
