import React, {
  useMemo,
  Dispatch,
  useState,
  MouseEvent,
  ChangeEvent,
  useCallback,
  KeyboardEvent,
  SetStateAction,
  FormEventHandler,
} from 'react';
import { UsernameField, PasswordField } from '@Components/forms';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import Placeholder from 'react-bootstrap/esm/Placeholder';
import { ButtonErrorSwapper } from '@Components/displays';
import { ShowModal, UserEntryError } from '@Interfaces';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import { handleOverlay, handleFeedback } from './fn';
import { PopoverTarget } from '@Components/buttons';
import Button from 'react-bootstrap/esm/Button';
import { APP, ALERT_TIMEOUT } from '@CONSTANTS';
import { createBrowserHistory } from 'history';
import Stack from 'react-bootstrap/esm/Stack';
import Form from 'react-bootstrap/esm/Form';
import { SplashPath, Props } from '@Types';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { handleKeyDown } from '@Utils';
import './style.css';

const Logio = ({
  setForgotPwOverlayTarget,
  safelyHandleLastSplash,
  handleUserFormChange,
  isPendingValidation,
  setCredentialsPath,
  userFormValidated,
  credentialsPath,
  handleUserEntry,
  setShowOverlay,
  userEntryError,
  userEntryForm,
  checkedTerms,
  setShowModal,
  swapToButton,
  showOverlay,
  isDesktop,
  loggingIn,
  setSplash,
  showModal,
  splash,
}: Props) => {
  // credentials submit status
  const handleSwapSubmitStatus = useCallback(() => {
    const swapper = setTimeout(
      () => (swapToButton as () => void)(),
      ALERT_TIMEOUT.btnErrSwapper
    );
    return () => clearTimeout(swapper);
  }, [swapToButton]);

  const [prevShowError, setPrevShowError] = useState(
    (userEntryError as UserEntryError).showError
  );
  if (prevShowError !== (userEntryError as UserEntryError).showError) {
    setPrevShowError((userEntryError as UserEntryError).showError);
    if ((userEntryError as UserEntryError).showError)
      handleSwapSubmitStatus();
  }

  const isJoinNow = useMemo(() => {
    return (
      (splash === 'Join Now' && !(showModal as ShowModal).credentials) ||
      credentialsPath === 'Join Now'
    );
  }, [splash, showModal, credentialsPath]);

  const handleSubmitBtnFace = useMemo(() => {
    return (
      (loggingIn && !(userEntryError as UserEntryError).showError && (
        <Placeholder
          as={Button}
          animation={'wave'}
          className={'continue-btn-placeholder'}>
          <i className={'bi bi-hourglass-split fs-5'} />
        </Placeholder>
      )) || (
        <ButtonErrorSwapper
          value={
            (splash === 'Tour' || splash === ''
              ? credentialsPath
              : splash) as string
          }
          {...(userEntryError as UserEntryError)}
        />
      )
    );
  }, [credentialsPath, userEntryError, loggingIn, splash]);

  const forceShowTerms = useCallback(() => {
    if (checkedTerms) {
      return;
    } else
      (setShowModal as Dispatch<SetStateAction<ShowModal>>)((s) => ({
        ...s,
        terms: true,
      }));
  }, [checkedTerms, setShowModal]);

  // browser history

  // let history = createBrowserHistory();

  // const swapSplashHistory = useCallback(() => {
  //   history.replace('/', {
  //     splash: isJoinNow ? 'Sign In' : 'Join Now',
  //   });
  // }, [isJoinNow, history]);

  // const [prevSplash, setPrevSplash] = useState(splash);
  // if (prevSplash !== splash) {
  //   setPrevSplash(splash);
  //   history.replace('/', {
  //     splash: splash,
  //   });
  // }

  const handleSwapSubmitStatusForm = useCallback(() => {
    if (
      (!showModal as unknown as ShowModal).credentials &&
      splash !== 'Tour'
    ) {
      return;
    } else if (
      (showModal as ShowModal).credentials ||
      (splash as SplashPath) === 'Tour'
    ) {
      if (credentialsPath === 'Join Now')
        (setCredentialsPath as Dispatch<SetStateAction<SplashPath>>)(
          'Sign In'
        );
      else if (credentialsPath === 'Sign In')
        (setCredentialsPath as Dispatch<SetStateAction<SplashPath>>)(
          'Join Now'
        );
    } else {
      // swapSplashHistory
      if (splash === 'Join Now')
        (setSplash as Dispatch<SetStateAction<SplashPath>>)('Sign In');
      else if (splash === 'Sign In')
        (setSplash as Dispatch<SetStateAction<SplashPath>>)('Join Now');
    }
  }, [
    splash,
    setSplash,
    showModal,
    credentialsPath,
    // , swapSplashHistory
    setCredentialsPath,
  ]);

  // useEffect(
  //   () => (safelyHandleLastSplash as () => void)(),
  //   [history, safelyHandleLastSplash]
  // );

  const overlayWrapper = useCallback(
    (e: MouseEvent<HTMLSpanElement> | KeyboardEvent<HTMLSpanElement>) => {
      handleOverlay(e, setForgotPwOverlayTarget, setShowOverlay);
      (setShowModal as Dispatch<SetStateAction<ShowModal>>)((m) => ({
        ...m,
        credentials: false,
      }));
    },
    [setForgotPwOverlayTarget, setShowOverlay, setShowModal]
  );

  return (
    <Form
      noValidate
      id={'logio-form'}
      className={'text-center'}
      validated={userFormValidated}
      onSubmit={
        handleUserEntry as unknown as FormEventHandler<HTMLFormElement>
      }>
      <Form.Label visuallyHidden={true}>
        {isJoinNow
          ? 'CREATE POWERBACK ACCOUNT'
          : 'SIGN INTO POWERBACK ACCOUNT'}
      </Form.Label>
      <Row className={'text-center'}>
        <Col lg={12} className='mobile-credentials-fields'>
          {/* USERNAME AND PASSWORD */}
          <Row>
            <Col xs={12} className='input--translucent'>
              <UsernameField
                value={!userEntryForm ? '' : userEntryForm.username}
                autoComplete={'username'}
                feedback={handleFeedback(
                  { ...APP.CREDENTIALS.FEEDBACK.u },
                  isPendingValidation,
                  showOverlay,
                  isJoinNow
                )}
                onChange={
                  handleUserFormChange as (e: ChangeEvent<Element>) => void
                }
                hideFeedback={userFormValidated}
                label={isJoinNow ? 'email address' : 'username'}
              />
            </Col>
            <Col xs={12} className='input--translucent'>
              <PasswordField
                label={isJoinNow ? 'new password' : 'password'}
                isGenerating={isJoinNow && !userFormValidated}
                autoComplete={'current-password'}
                onChange={
                  handleUserFormChange as (e: ChangeEvent<Element>) => void
                }
                feedback={handleFeedback(
                  { ...APP.CREDENTIALS.FEEDBACK.p },
                  isPendingValidation,
                  showOverlay,
                  isJoinNow
                )}
                value={!userEntryForm ? '' : userEntryForm.password}
              />
            </Col>
          </Row>
        </Col>

        {/* Submit Button */}
        <Col xs={12} className='cta-form-btn'>
          {handleSubmitBtnFace}
        </Col>

        {/* TERMS AND  CONDITIONS? */}
        <Col className='toc flex-row'>
          <Stack gap={3} direction={'vertical'} className={'mt-1'}>
            {isJoinNow ? (
              <FormGroup controlId={'accept-terms'}>
                <Row className='justify-content-center align-content-lg-center'>
                  <Col xs={1} lg={'auto'}>
                    <Form.Check
                      onChange={forceShowTerms}
                      checked={checkedTerms}
                      required={isJoinNow}
                      id={'accept-terms'}
                      type={'checkbox'}
                    />
                  </Col>
                  <Col xs={'auto'} lg={'auto'}>
                    <FormCheckLabel htmlFor='accept-terms'>
                      <div>
                        <i className={'bi bi-hourlgass-split float'} />
                        {APP.CREDENTIALS.AGREE[1]}
                        <span
                          onClick={forceShowTerms as (e: any) => void}
                          onKeyDown={(e) =>
                            handleKeyDown(e, forceShowTerms, e)
                          }
                          className={'terms-link natural-link'}>
                          <u tabIndex={0}>{APP.CREDENTIALS.AGREE[2]}</u>
                        </span>
                        .
                      </div>
                    </FormCheckLabel>
                  </Col>
                </Row>
              </FormGroup>
            ) : (
              // FORGOT PASSWORD?
              <PopoverTarget handleOverlay={overlayWrapper}>
                {APP.CREDENTIALS.FORGOT_PW}
              </PopoverTarget>
            )}

            {/* LINK TO SWITCH CREDENTIALS FORMS */}
            <u
              className={'natural-link inconsolata'}
              onKeyDown={(e) =>
                handleKeyDown(e, handleSwapSubmitStatusForm)
              }
              onClick={handleSwapSubmitStatusForm}>
              {APP.CREDENTIALS.SWITCH[+isJoinNow]}
            </u>
          </Stack>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(Logio);
