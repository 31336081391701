import React, {
  useMemo,
  Dispatch,
  useState,
  useEffect,
  ChangeEvent,
  SetStateAction,
} from 'react';
import { Props } from '@Types';
import { Splash } from '@Pages';
import { routes } from 'router';
import { Route } from 'type-route';
import { ShowModal, ShowOverlay } from '@Interfaces';
import { Footer, Wrapper, Navigation } from '@Components/page';

const SideNav = React.lazy(
    () => import('../components/page/navs/SideNav')
  ),
  CredentialsModal = React.lazy(
    () => import('../components/modals/Credentials')
  ),
  ForgotPasswordOverlay = React.lazy(
    () => import('../components/modals/ForgotPassword')
  ),
  FAQModal = React.lazy(
    () => import('../components/page/navs/modals/FAQ')
  ),
  TermsModal = React.lazy(
    () => import('../components/page/navs/modals/Terms')
  ),
  EligibilityModal = React.lazy(
    () => import('../components/page/navs/modals/Eligibility')
  ),
  Main = React.lazy(() => import('../pages/Main/Main')),
  Reset = React.lazy(() => import('../pages/Reset'));

const Page = ({
  loggedInBeforePaymentGuard,
  setUserIsAssumedValid,
  handleUserFormChange,
  setUserFormValidated,
  switchToErrorScreen,
  setCredentialsPath,
  userIsAssumedValid,
  accountActivated,
  homeLinkRedirect,
  credentialsPath,
  optionCategory,
  serverHasError,
  setShowOverlay,
  setShowSideNav,
  handleLogOut,
  setShowAlert,
  setShowModal,
  showOverlay,
  showSideNav,
  setSplash,
  showAlert,
  showModal,
  loggedIn,
  isMobile,
  splash,
  tabKey,
  route,
  ...props
}: Props) => {
  const [secureUserPassFeedback, setSecureUserPassFeedback] = useState(''),
    [linkIsExpired, setLinkIsExpired] = useState(false); // for reset pw etc

  // global modals
  const MODALS = useMemo(() => {
    return [
      { element: FAQModal, key: 'faq-modal-' },
      { element: TermsModal, key: 'terms-modal-' },
      { element: EligibilityModal, key: 'eligibility-modal-' },
      { element: CredentialsModal, key: 'credentials-modal-' },
      { element: ForgotPasswordOverlay, key: 'forgotpassword-overlay-' },
    ];
  }, []);

  useEffect(() => {
    if (
      // reset password
      (route as Route<typeof routes>).name === 'reset' &&
      (!userIsAssumedValid || linkIsExpired)
    )
      (homeLinkRedirect as () => void)();
  }, [route, linkIsExpired, userIsAssumedValid, homeLinkRedirect]);

  const [forgotPwOverlayTarget, setForgotPwOverlayTarget] = useState(null),
    [emailToSendPasswordLink, setEmailToSendPasswordLink] = useState('');

  return (
    <>
      {MODALS.map((modal) => {
        const Modal = modal.element;
        return (
          <Modal
            {...props}
            key={modal.key + '-nouser'}
            setShowOverlay={
              setShowOverlay as Dispatch<SetStateAction<ShowOverlay>>
            }
            loggedInBeforePaymentGuard={loggedInBeforePaymentGuard}
            setForgotPwOverlayTarget={
              setForgotPwOverlayTarget as Dispatch<
                SetStateAction<HTMLSpanElement | null>
              >
            }
            forgotPwOverlayTarget={forgotPwOverlayTarget}
            setUserIsAssumedValid={setUserIsAssumedValid}
            handleUserFormChange={handleUserFormChange}
            setUserFormValidated={setUserFormValidated}
            switchToErrorScreen={switchToErrorScreen}
            showOverlay={showOverlay as ShowOverlay}
            setCredentialsPath={setCredentialsPath}
            userIsAssumedValid={userIsAssumedValid}
            setEmail={setEmailToSendPasswordLink}
            accountActivated={accountActivated}
            showModal={showModal as ShowModal}
            credentialsPath={credentialsPath}
            email={emailToSendPasswordLink}
            setShowAlert={setShowAlert}
            setShowModal={setShowModal}
            setSplash={setSplash}
            showAlert={showAlert}
            isMobile={isMobile}
            loggedIn={loggedIn}
            splash={splash}
            route={route}
          />
        );
      })}

      <Navigation
        {...props}
        setShowSideNav={
          setShowSideNav as Dispatch<SetStateAction<boolean>>
        }
        handleLogOut={handleLogOut as unknown as () => void}
        serverHasError={serverHasError as boolean}
        setCredentialsPath={setCredentialsPath}
        showSideNav={showSideNav as boolean}
        credentialsPath={credentialsPath}
        loggedIn={loggedIn as boolean}
        setShowAlert={setShowAlert}
        setShowModal={setShowModal}
        isMobile={isMobile}
        splash={splash}
        route={route}
      />
      {isMobile && (
        <SideNav
          {...props}
          setShow={setShowSideNav as Dispatch<SetStateAction<boolean>>}
          handleLogOut={handleLogOut as unknown as () => void}
          show={showSideNav as boolean}
          setShowAlert={setShowAlert}
          setShowModal={setShowModal}
          setSplash={setSplash}
          showAlert={showAlert}
          isMobile={isMobile}
          loggedIn={loggedIn}
          splash={splash}
        />
      )}

      <Wrapper classProps={'wrapper-' + tabKey}>
        {(route as Route<typeof routes>).name === 'reset' &&
          userIsAssumedValid &&
          !linkIsExpired && (
            <Reset
              {...props}
              setUserIsAssumedValid={
                setUserIsAssumedValid as Dispatch<SetStateAction<boolean>>
              }
              setSecureUserPassFeedback={setSecureUserPassFeedback}
              handleChange={
                handleUserFormChange as (e: ChangeEvent) => void
              }
              switchToErrorScreen={
                switchToErrorScreen as (err: Error) => void
              }
              setUserFormValidated={setUserFormValidated}
              showErr={showAlert ? showAlert.err : false}
              homeLinkRedirect={homeLinkRedirect}
              setLinkIsExpired={setLinkIsExpired}
              uFeedback={secureUserPassFeedback}
              setShowAlert={setShowAlert}
              isMobile={isMobile}
              splash={splash}
            />
          )}
        {((route as Route<typeof routes>).name === 'main' ||
          accountActivated) &&
          optionCategory &&
          (loggedIn || splash === 'Tour' ? (
            <Main
              {...props}
              route={route}
              splash={splash}
              tabKey={tabKey}
              isMobile={isMobile}
              loggedIn={loggedIn}
              showAlert={showAlert}
              showModal={showModal}
              setSplash={setSplash}
              showSideNav={showSideNav}
              handleLogOut={handleLogOut}
              setShowAlert={setShowAlert}
              setShowModal={setShowModal}
              optionCategory={optionCategory}
              serverHasError={serverHasError}
              setShowSideNav={setShowSideNav}
              credentialsPath={credentialsPath}
              accountActivated={accountActivated}
              setCredentialsPath={setCredentialsPath}
              userIsAssumedValid={userIsAssumedValid}
              showOverlay={showOverlay as ShowOverlay}
              switchToErrorScreen={switchToErrorScreen}
              handleUserFormChange={handleUserFormChange}
              setUserIsAssumedValid={setUserIsAssumedValid}
              secureUserPassFeedback={secureUserPassFeedback}
              setSecureUserPassFeedback={setSecureUserPassFeedback}
              loggedInBeforePaymentGuard={loggedInBeforePaymentGuard}
            />
          ) : (
            <Splash
              {...props}
              setShowOverlay={
                setShowOverlay as Dispatch<SetStateAction<ShowOverlay>>
              }
              setForgotPwOverlayTarget={
                setForgotPwOverlayTarget as Dispatch<
                  SetStateAction<HTMLSpanElement | null>
                >
              }
              forgotPwOverlayTarget={forgotPwOverlayTarget}
              setUserIsAssumedValid={setUserIsAssumedValid}
              handleUserFormChange={handleUserFormChange}
              setOverlayTarget={setForgotPwOverlayTarget}
              switchToErrorScreen={switchToErrorScreen}
              userIsAssumedValid={userIsAssumedValid}
              accountActivated={accountActivated}
              credentialsPath={credentialsPath}
              optionCategory={optionCategory}
              serverHasError={serverHasError}
              setShowSideNav={setShowSideNav}
              handleLogOut={handleLogOut}
              setShowAlert={setShowAlert}
              setShowModal={setShowModal}
              showSideNav={showSideNav}
              setSplash={setSplash}
              showAlert={showAlert}
              showModal={showModal}
              isMobile={isMobile}
              loggedIn={loggedIn}
              splash={splash}
              tabKey={tabKey}
              route={route}
            />
          ))}
      </Wrapper>
      <Footer
        setShowAlert={setShowAlert}
        isMobile={isMobile}
        splash={splash}
      />
    </>
  );
};

export default React.memo(Page);
