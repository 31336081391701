module.exports = {
  ICONS: [
    {
      type: 'text',
      iconL: 'unlock',
      iconR: 'eye-slash',
    },
    {
      type: 'password',
      iconL: 'lock',
      iconR: 'eye-fill',
    },
  ],
};
