const not_found = 'District not found. Please try again with a ';

module.exports = {
  LOGO_DIMS: {
    // NavBrand img svg
    default: { width: 32, height: 32 },
  },
  APP: {
    BG_ARIA_LABEL:
      'a colorless hand passes a burning $100 bill to another hand sleeved in a suit, the image is distorted and appears to be in motion, laminous neon pinks and purples add a vibrant contrast',
    URI_SUBTRAHEND: 18,
    EXPLAINER_BTN_LABEL: 'Watch the explainer ▶',
    EXPLAINER_HEADING:
      'Click \xA0▶ \xA0below to watch a brief explainer video about how our service works',
    LOADING: 'Reticulating Shills...',
    // menu interface labels
    SPLASH_PATHS: ['Join Now', 'Sign In', 'Explainer', 'Tour', ''],
    NAV: {
      MODALS: ['FAQ', 'Eligibility', 'Terms'],
      SIDENAV_TOUR: [
        { cls: 'pt-5 pb-3', label: 'Explainer', icon: 'play-circle' },
        {
          cls: 'py-3',
          label: 'Join Now',
          icon: 'person-add',
        },
        {
          cls: 'py-3',
          label: 'Sign In',
          icon: 'box-arrow-right',
        },
      ],
    },
    CREDENTIALS: {
      TO_START: ['need to be logged in', 'must have an account'],
      AGREE: { 1: 'I have read and agree to the ', 2: 'terms of use' },
      SWITCH: [
        'Need an Account? Join Now',
        'I Have an Account and wish to Sign In!',
      ],
      FORGOT_PW: 'Forgot Password?',
      FEEDBACK: {
        u: {
          in: 'Please enter your username email.',
          up: 'Enter your email address for the username.',
        },
        p: {
          in: 'Please enter your password to login.',
          up: 'Please choose a password to secure your account.',
        },
      },
    },
    SEARCH: {
      DROPDOWN: {
        ODD: { color: '#CCC', backgroundColor: '#201820' },
        EVEN: { color: '#CCC', backgroundColor: '#182018' },
        SELECTED: { color: '#0F0', backgroundColor: '#101010' },
      },
      COSPONSORS:
        "Filter your search results to the bill's cosponsors. See the FAQ for more info.",
      DISTRICT: {
        NOTFOUND: not_found + 'different ZIP code.',
        TOOSHORT: not_found + 'complete 5-digit ZIP code in your query.',
        SPLIT:
          'Your area may be split across multiple districts. Please try again with a more complete address, and/or a different ZIP code.',
        NO_CHALLENGER: [
          'The incumbent for District ',
          ' of ',
          "has no serious, financed challenger. (In other words, they don't need any money!)",
        ],
        PLACEHOLDERS: [
          'Type address+/ZIP code, then click "Find" or press ENTER.',
          'Search by ZIP code.',
        ],
      },
    },
    ACCOUNT_TABS: [
      { key: 1, topic: 'Profile' },
      { key: 2, topic: 'Celebrations' },
      { key: 3, topic: 'Settings' },
    ],
    SECURITY_BUTTONS: ['Change Password', 'Delete Account'],
  },
  RESET: {
    uLabel: 'username',
    pLabel: 'NEW password',
    buttonText: 'Reset password',
    title: 'Set Your New Password',
    pFeedback: 'Please enter a new password.',
  },
  SPLASH: {
    IMG_HEIGHT: 42,
    EASTER_EGG_URI: 'https://rb.gy/jm1zd',
    SLOGAN: 'No Donation Without Representation!',
    COPY: {
      cta: 'Take the power back.',
      video: {
        open: 'Learn how it works in 27s ▶ ',
        close: '◼ Close player and return',
      },
      tour: 'Try it out >>',
      intro:
        ' infuses your election campaign donations with political capital by securely stockpiling contributions towards real-world legislative action.',
    },
    EXT_URI_SETTINGS:
      'toolbar=yes,location=yes,status=no,menubar=yes,scrollbars=yes,resizable=yes,width=420,height=420',
  },
  CITATIONS: {
    OS: {
      u: 'OpenSecrets.org',
      s: { sm: 33, lg: 44 },
      a: 'https://rb.gy/ehttv2',
    },
    PP: {
      u: 'ProPublica.org',
      s: { sm: 27, lg: 36 },
      a: 'https://rb.gy/3obrj9',
    },
    GG: {
      a: 'https://my.greengeeks.com/seal/',
      i: 'https://static.greengeeks.com/ggseal/Green_14.png',
    },
  },
  ALERT_TIMEOUT: {
    join: 10000,
    login: 5000,
    reset: 12000,
    delete: 7500,
    update: 7000,
    activate: 7500,
    rejected: 8000,
    btnErrSwapper: 4200,
  },
  DOSSIER: {
    role: 'campaign finance data and voting behavior predictive analysis about the representative',

    pai: {
      tip: 'The POWERBACK Partisan Autonomy Index (Ꭾ) is a measure of how likely a representative is to cast a vote against the direction of their party.',
      sub: 'PARTISAN AUTONOMY INDEX',
      cit: 'Voting records data:',
    },
    top: {
      tip: 'Raised totals from both individuals and companies across the Health sectors, not just POWERBACK users. Rankings relative to the rest of the House are in the right column.',
      sub: 'FUNDS RAISED',
      cit: 'Fundraising data:',
    },
  },
  CELEBRATION_SCREEN_LOAD_HEADER:
    'START A CELEBRATION BY CHOOSING A REPRESENTATIVE AND CASH AMOUNT.',
  AMOUNT_PROMPT: 'Choose Your Amount:',
  M4A: 'hr3421-118',
  PATREON: {
    URI: 'https://rb.gy/1b7gby',
    SETTINGS:
      'toolbar=yes,location=yes,status=no,menubar=yes,scrollbars=yes,resizable=yes,width=420,height=420',
  },
  GIT_REPO: 'https://rb.gy/20h36a',
  AMOUNT: {
    reached:
      'You have already reached your personal limit for giving to this candidate during this Congressional session. However, you are free to choose another candidate. (Or, consider supporting POWERBACK!)',
    exceeds: [
      "The amount you've chosen exceeds your remaining personal limit (",
      ') for this candidate during this campaign cycle. (Please consider supporting POWERBACK!)',
    ],
  },
  SUGGESTED: [
    [5, 10, 25, 50], // non-compliant
    [25, 100, 250, 1000], // compliant
  ],
  FEC: {
    LEGAL_LIMIT: [200, 3300],
    NAME: 'Federal Election Commission',
    NOTICE_HEADING: '52 U.S. Code § 30101',
    LAW_UNDER: 'Federal Election Campaign Act',
    URI: [
      'https://www.fec.gov/help-candidates-and-committees/filing-reports/individual-contributions/',
      'https://www.fec.gov/help-candidates-and-committees/candidate-taking-receipts/contribution-limits/',
      'https://www.fec.gov/updates/contribution-limits-for-2023-2024/',
    ],
  },
  BACKUP_IMG: 'https://clerk.house.gov/images/members/',
  CHECKOUT: {
    BILL_DESCRIPTION_CHAR_LIMIT: {
      XS: 10,
      SM: 20,
      MD: 25,
      LG: 32,
    },
    STATEMENT:
      ' is the only not-for-profit, nonpartisan election campaign donation platform that powers urgent change backed by millions of Americans.',
    SURCHARGE: {
      PERCENTAGE: 0.03,
      ADDEND: 0.3,
      INFO: [
        'As a nonprofit we do not make any money off these surcharges and rely solely on ',
        'direct donations',
      ],
    },
  },
  RECOMMENDED: {
    TIPS: {
      LOW: 1,
      HIGH: 2,
      THRESHOLD: 20,
    },
  },
};
