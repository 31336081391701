export const ORGANIZATIONS = require('./organizations'),
  POLICY_AREAS = require('./policyAreas'),
  GEOGRAPHIC = require('./geographic'),
  COUNTRIES = require('./countries'),
  POLSTATES = require('./polStates'),
  SUBJECTS = require('./subjects'),
  ERRORS = require('./errors'),
  STATES = require('./states'),
  RULES = require('./rules'),
  TERMS = require('./terms'),
  FAQ = require('./faq');
