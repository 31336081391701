import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
} from 'react';

type Props = {
  handleOverlay: KeyboardEventHandler<HTMLSpanElement> &
    MouseEventHandler<HTMLSpanElement>;
  children: ReactNode;
};

const PopoverTarget = ({ handleOverlay, ...props }: Props) => (
  <span
    tabIndex={0}
    className={'forgot-pass-link natural-link'}
    onKeyDown={handleOverlay}
    onClick={handleOverlay}>
    {props.children}
  </span>
);

export default React.memo(PopoverTarget);
