import React, {
  Dispatch,
  useState,
  useCallback,
  SetStateAction,
} from 'react';
import CloseButton from 'react-bootstrap/esm/CloseButton';
import { ShowOverlay, ShowAlert } from '@Interfaces';
import Overlay from 'react-bootstrap/esm/Overlay';
import Popover from 'react-bootstrap/esm/Popover';
import Stack from 'react-bootstrap/esm/Stack';
import ForgotPasswordForm from './form';
import { ShowModal } from '@Interfaces';
import './style.css';

type ForgotPasswordProps = {
  setShowOverlay: Dispatch<SetStateAction<ShowOverlay>>;
  setShowAlert: Dispatch<SetStateAction<ShowAlert>>;
  forgotPwOverlayTarget: HTMLSpanElement | null;
  setEmail: Dispatch<SetStateAction<string>>;
  showOverlay: ShowOverlay;
  showModal: ShowModal;
  email: string;
};

const ForgotPasswordOverlay = ({
  forgotPwOverlayTarget,
  setShowOverlay,
  setShowAlert,
  showOverlay,
  showModal,
  setEmail,
  email,
}: ForgotPasswordProps) => {
  const [validated, setValidated] = useState(false),
    handleClose = useCallback(
      () =>
        setShowOverlay((s: ShowOverlay) => ({ ...s, resetPass: false })),
      [setShowOverlay]
    ),
    handleExit = useCallback(() => setValidated(false), []);

  let exceptCredentialsModal = Object.assign({}, showModal);
  delete (exceptCredentialsModal as any)['credentials'];

  return (
    <Overlay
      placement={'top'}
      onExiting={handleExit}
      target={forgotPwOverlayTarget}
      show={
        showOverlay.resetPass &&
        Object.values(exceptCredentialsModal).every((o) => !o)
      } // overlay otherwise covered by other modals
    >
      <Popover id='forgot-pass-pop'>
        <Popover.Header>
          <Stack direction='horizontal'>
            Request to change password
            <CloseButton
              onClick={handleClose}
              aria-label={'hide forgot password prompt'}
            />
          </Stack>
        </Popover.Header>
        <Popover.Body>
          <div className='form-prompt'>
            Enter your account's Profile email.
            <br />
            (This may be same as your username)
          </div>
          <ForgotPasswordForm
            setShowOverlay={setShowOverlay}
            setValidated={setValidated}
            setAlert={setShowAlert}
            validated={validated}
            setEmail={setEmail}
            email={email}
          />
        </Popover.Body>
      </Popover>
    </Overlay>
  );
};

export default React.memo(ForgotPasswordOverlay);
