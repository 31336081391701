import API from '@API';

export const updateUser = (
  { id, donations },
  switchToErrorScreen,
  setUserData,
  info
) => {
  if (!id || !donations) {
    return;
  } else {
    let updatesObj = info;
    const userDonations = donations;
    if (Object.keys(info).includes('settings')) {
      let strSettings = JSON.stringify(info.settings);
      sessionStorage.setItem('powerback_settings', strSettings);
    }
    API.updateUser(id, info) // api call
      .then(() => {
        updatesObj.donations = userDonations;
        setUserData((u) => ({ ...u, ...updatesObj })); // update user state and re-attach donations array
      })
      .catch((err) => switchToErrorScreen(err));
  }
};
