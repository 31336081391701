import React, {
  useMemo,
  Dispatch,
  Suspense,
  useState,
  useReducer,
  useCallback,
  SetStateAction,
  useLayoutEffect,
} from 'react';
import { AccordionSelectCallback } from 'react-bootstrap/esm/AccordionContext';
import {
  UserData,
  ShowAlert,
  ShowModal,
  ShowOverlay,
  PolDonations,
} from '@Interfaces';
import Joyride, { STATUS, CallBackProps } from 'react-joyride';
import TabContainer from 'react-bootstrap/esm/TabContainer';
import Container from 'react-bootstrap/esm/Container';
import { useRoute, routes } from '../../../../router';
import { LOCALE, OPTIONS, TOUR_STEPS } from './tour';
import { useMediaQuery } from 'react-responsive';
import { ALERT_TIMEOUT } from '@CONSTANTS';
import { Route } from 'type-route';
import { Props } from '@Types';
import {
  AlertLoginLogout,
  AlertDonationRejected,
} from '@Components/alerts';
import API from '@API';
import './style.css';

const ExplainerModal = React.lazy(() => import('./modals/Explainer')),
  AccountModal = React.lazy(() => import('./modals/Account')),
  TabContents = React.lazy(() => import('./TabContents'));

const Celebrate = ({
  loggedInBeforePaymentGuard,
  rejectedDonationReasons,
  switchToErrorScreen,
  historyLengthRef,
  setShowModal,
  setShowAlert,
  showOverlay,
  isDesktop,
  setTabKey,
  showAlert,
  showModal,
  isMobile,
  loggedIn,
  userData,
  tabKey,
  ...props
}: Props) => {
  const [totalCelebrations, setTotalCelebrations] = useState<
      PolDonations | []
    >([]),
    getEscrow = useCallback(
      () =>
        API.getWhatPolsHaveInEscrow()
          .then((res) => {
            const { data: escrow } = res;
            return escrow;
          })
          .then((escrow) =>
            (
              setTotalCelebrations as Dispatch<
                SetStateAction<PolDonations>
              >
            )(escrow)
          )
          .catch((err) => {
            if (err.response.status === 500)
              (switchToErrorScreen as (err: Error) => void)(err);
            else console.error(err);
          }),
      [setTotalCelebrations, switchToErrorScreen]
    );

  const [polsAreLoaded, setPolsAreLoaded] = useReducer((state) => {
    return (state = true);
  }, false);

  useLayoutEffect(() => {
    let ignore = false;
    if (!ignore) getEscrow();
    return () => {
      ignore = true;
    };
  }, [getEscrow, userData]);

  const alerts = useMemo(() => {
    return [
      { key: 'login', element: AlertLoginLogout },
      {
        key: 'rejected',
        element: AlertDonationRejected,
      },
    ];
  }, []);

  const onSelectTab = useCallback(
    (tab: any) => (setTabKey as Dispatch<SetStateAction<string>>)(tab),
    [setTabKey]
  );

  const route = useRoute();

  const responsiveTOUR_STEPS = useMemo(() => {
    return isDesktop
      ? TOUR_STEPS.filter((ts) => ts.data !== 'sm')
      : TOUR_STEPS.filter((ts) => ts.data !== 'lg');
  }, [isDesktop]);

  const isShortMobile = useMediaQuery({
    query: '((max-height: 640px) and (orientation: portrait))',
  });

  const stopTourFromReoccuring = useCallback((data: CallBackProps) => {
    const { status } = data;
    if (STATUS.FINISHED.includes(status))
      localStorage.setItem('showPowerbackTour', 'false');
  }, []);

  const toolTipWidth = useMemo(() => {
      return isDesktop ? '18vw' : '85vw';
    }, [isDesktop]),
    toolTipOverlayColor = useMemo(() => {
      return isDesktop ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.68)';
    }, [isDesktop]);

  const runTour =
    !loggedIn &&
    polsAreLoaded &&
    !loggedInBeforePaymentGuard &&
    !(showModal as unknown as ShowModal).credentials &&
    !(showOverlay as unknown as ShowOverlay).resetPass &&
    localStorage.getItem('showPowerbackTour') !== 'false';

  return (
    <>
      <AccountModal
        key={(userData as UserData).id + '-account-modal'}
        switchToErrorScreen={switchToErrorScreen}
        isShortMobile={isShortMobile}
        setShowAlert={setShowAlert}
        setShowModal={setShowModal}
        isDesktop={isDesktop}
        setTabKey={setTabKey}
        showAlert={showAlert}
        showModal={showModal}
        isMobile={isMobile}
        loggedIn={loggedIn}
        userData={userData}
        tabKey={tabKey}
        {...props}
      />

      {isDesktop && (
        <ExplainerModal
          key={(userData as UserData).id + '-explainer-modal'}
          switchToErrorScreen={switchToErrorScreen}
          setShowAlert={setShowAlert}
          setShowModal={setShowModal}
          setTabKey={setTabKey}
          showAlert={showAlert}
          showModal={showModal}
          loggedIn={loggedIn}
          userData={userData}
          isMobile={false}
          tabKey={tabKey}
          {...props}
        />
      )}

      {alerts.map((alert) => {
        const CustomAlert = alert.element;
        return (
          <CustomAlert
            setShow={setShowAlert}
            key={'alert-' + alert.key}
            {...rejectedDonationReasons}
            loggedIn={loggedIn as boolean}
            show={showAlert as ShowAlert & boolean}
            timeout={(ALERT_TIMEOUT as any)[alert.key] as number}
          />
        );
      })}

      <Container id='celebrate--page'>
        <Joyride
          styles={{
            options: {
              ...OPTIONS,
              width: toolTipWidth,
              overlayColor: toolTipOverlayColor,
            },
          }}
          callback={stopTourFromReoccuring}
          steps={responsiveTOUR_STEPS}
          locale={LOCALE}
          spotlightClicks
          showSkipButton
          run={runTour}
          showProgress
          continuous
        />
        <Suspense fallback={<></>}>
          {(route as Route<typeof routes>).name === 'main' && (
            <TabContainer
              onSelect={onSelectTab as AccordionSelectCallback}
              defaultActiveKey={'pol-donation'}
              id={'celebrate-tab-container'}
              activeKey={tabKey}>
              <TabContents
                {...props}
                switchToErrorScreen={switchToErrorScreen}
                totalCelebrations={totalCelebrations}
                setPolsAreLoaded={setPolsAreLoaded}
                historyLengthRef={historyLengthRef}
                isShortMobile={isShortMobile}
                polsAreLoaded={polsAreLoaded}
                setShowAlert={setShowAlert}
                setShowModal={setShowModal}
                getEscrow={getEscrow}
                isDesktop={isDesktop}
                showAlert={showAlert}
                showModal={showModal}
                setTabKey={setTabKey}
                isMobile={isMobile}
                loggedIn={loggedIn}
                userData={userData}
                tabKey={tabKey}
              />
            </TabContainer>
          )}
        </Suspense>
      </Container>
    </>
  );
};

export default React.memo(Celebrate);
