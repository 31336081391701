module.exports = [
  'Access Board',
  'Administrative Conference of the U.S.',
  'African Development Foundation',
  'American Battle Monuments Commission',
  'Appalachian Regional Commission',
  'Architect of the Capitol',
  'Armed Forces Retirement Home',
  'Arms Control and Disarmament Agency',
  'Army Corps of Engineers',
  'Centers for Disease Control and Prevention (CDC)',
  'Central Intelligence Agency (CIA)',
  'Commission of Fine Arts',
  'Commission on Civil Rights',
  'Committee for Purchase from People Who Are Blind or Severely Disabled',
  'Commodity Credit Corporation',
  'Commodity Futures Trading Commission',
  'Community Development Financial Institutions Fund',
  'Congressional Budget Office (CBO)',
  'Congressional Research Service (CRS)',
  'Consumer Financial Protection Bureau',
  'Consumer Product Safety Commission',
  'Corporation for National and Community Service',
  'Corporation for Public Broadcasting',
  'Defense Nuclear Facilities Safety Board',
  'Delaware River Basin Commission',
  'Delta Regional Authority',
  'Denali Commission',
  'Department of Agriculture',
  'Department of Commerce',
  'Department of Defense',
  'Department of Education',
  'Department of Energy',
  'Department of Health and Human Services',
  'Department of Health, Education, and Welfare',
  'Department of Homeland Security',
  'Department of Housing and Urban Development',
  'Department of Justice',
  'Department of Labor',
  'Department of State',
  'Department of Transportation',
  'Department of Veterans Affairs',
  'Department of the Interior',
  'Department of the Treasury',
  'Director of National Intelligence',
  'Drug Enforcement Administration (DEA)',
  'Election Assistance Commission',
  'Environmental Protection Agency (EPA)',
  'Equal Employment Opportunity Commission (EEOC)',
  'European Union',
  'Executive Office of the President',
  'Export-Import Bank of the United States',
  'Farm Credit Administration',
  'Federal Bureau of Investigation (FBI)',
  'Federal Communications Commission (FCC)',
  'Federal Crop Insurance Corporation',
  'Federal Deposit Insurance Corporation (FDIC)',
  'Federal Election Commission (FEC)',
  'Federal Emergency Management Agency (FEMA)',
  'Federal Energy Regulatory Commission (FERC)',
  'Federal Home Loan Bank Board',
  'Federal Housing Finance Agency',
  'Federal Labor Relations Authority',
  'Federal Maritime Commission',
  'Federal Mediation and Conciliation Service',
  'Federal Mine Safety and Health Review Commission',
  'Federal Prison Industries, Inc.',
  'Federal Reserve System',
  'Federal Retirement Thrift Investment Board',
  'Federal Trade Commission (FTC)',
  'Financial Stability Oversight Council',
  'Food and Drug Administration (FDA)',
  'General Services Administration',
  'Government Accountability Office (GAO)',
  'Government National Mortgage Association (Ginnie Mae)',
  'Government Publishing Office (GPO)',
  'House Committee on Agriculture',
  'House Committee on Appropriations',
  'House Committee on Armed Services',
  'House Committee on Education and Labor',
  'House Committee on Energy and Commerce',
  'House Committee on Ethics',
  'House Committee on Financial Services',
  'House Committee on Foreign Affairs',
  'House Committee on Homeland Security',
  'House Committee on House Administration',
  'House Committee on Natural Resources',
  'House Committee on Oversight and Government Reform',
  'House Committee on Oversight and Reform',
  'House Committee on Rules',
  'House Committee on Science, Space, and Technology',
  'House Committee on Small Business',
  'House Committee on Standards of Official Conduct',
  'House Committee on Transportation and Infrastructure',
  "House Committee on Veterans' Affairs",
  'House Committee on Ways and Means',
  'House Committee on the Budget',
  'House Committee on the Judiciary',
  'House Permanent Select Committee on Intelligence',
  'House Select Committee on Energy Independence and Global Warming',
  'Inter-American Foundation',
  'Interagency Council on Homelessness',
  'Internal Revenue Service (IRS)',
  'Japan-U.S. Friendship Commission',
  'Joint Committee on Printing',
  'Joint Committee on Taxation',
  'Joint Committee on the Library',
  'Joint Economic Committee',
  'Legal Services Corporation',
  'Library of Congress',
  'Marine Mammal Commission',
  'Medicare Payment Advisory Commission',
  'Merit Systems Protection Board',
  'National Aeronautics and Space Administration',
  'National Archives and Records Administration',
  'National Capital Planning Commission',
  'National Commission on Libraries and Information Science',
  'National Council on Disability',
  'National Credit Union Administration',
  'National Foundation on the Arts and the Humanities',
  'National Indian Gaming Commission',
  'National Institutes of Health (NIH)',
  'National Labor Relations Board (NLRB)',
  'National Mediation Board',
  'National Railroad Passenger Corporation (Amtrak)',
  'National Science Foundation',
  'National Security Agency',
  'National Transportation Safety Board (NTSB)',
  'Neighborhood Reinvestment Corporation',
  'Nuclear Regulatory Commission (NRC)',
  'Nuclear Waste Technical Review Board',
  'Occupational Safety and Health Review Commission',
  'Office of Government Ethics',
  'Office of Management and Budget (OMB)',
  'Office of Personnel Management (OPM)',
  'Office of Science and Technology Policy',
  'Office of Special Counsel',
  'Office of the U.S. Trade Representative',
  'Organization of American States',
  'Peace Corps',
  'Pension Benefit Guaranty Corporation',
  'Postal Regulatory Commission',
  'Privacy and Civil Liberties Oversight Board',
  'Railroad Retirement Board',
  'Securities and Exchange Commission (SEC)',
  'Selective Service System',
  'Senate Committee on Agriculture, Nutrition, and Forestry',
  'Senate Committee on Appropriations',
  'Senate Committee on Armed Services',
  'Senate Committee on Banking, Housing, and Urban Affairs',
  'Senate Committee on Commerce, Science, and Transportation',
  'Senate Committee on Energy and Natural Resources',
  'Senate Committee on Environment and Public Works',
  'Senate Committee on Finance',
  'Senate Committee on Foreign Relations',
  'Senate Committee on Health, Education, Labor, and Pensions',
  'Senate Committee on Homeland Security and Governmental Affairs',
  'Senate Committee on Indian Affairs',
  'Senate Committee on Rules and Administration',
  'Senate Committee on Small Business and Entrepreneurship',
  "Senate Committee on Veterans' Affairs",
  'Senate Committee on the Budget',
  'Senate Committee on the Judiciary',
  'Senate Select Committee on Ethics',
  'Senate Select Committee on Intelligence',
  'Senate Special Committee on Aging',
  'Small Business Administration',
  'Smithsonian Institution',
  'Social Security Administration',
  'Surface Transportation Board',
  'Tennessee Valley Authority',
  'Trade and Development Agency',
  'U.S. Agency for Global Media',
  'U.S. Agency for International Development (USAID)',
  'U.S. Commission on International Religious Freedom',
  'U.S. Holocaust Memorial Council',
  'U.S. Institute of Peace',
  'U.S. International Development Finance Corporation',
  'U.S. International Trade Commission',
  'U.S. Postal Service',
  'U.S. Sentencing Commission',
  'United Nations',
];
