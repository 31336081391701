export const entry = (
  e,
  {
    login,
    joinUp,
    splash,
    loggingIn,
    credentialsPath,
    setUserFormValidated,
    startLoggingInSpinner,
    ...setters
  }
) => {
  e.preventDefault();
  e.stopPropagation();
  if (loggingIn) {
    return;
  } else {
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      setUserFormValidated((s) => (s = true));
    } else {
      startLoggingInSpinner();
      if (splash === 'Join Now' || credentialsPath === 'Join Now')
        joinUp({ setUserFormValidated, ...setters });
      else login({ setUserFormValidated, ...setters });
    }
  }
};
