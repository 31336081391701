import React, {
  useRef,
  useMemo,
  Dispatch,
  useState,
  useEffect,
  RefObject,
  useCallback,
  SetStateAction,
  useLayoutEffect,
  MutableRefObject,
  StyleHTMLAttributes,
  TransitionStartFunction,
} from 'react';
import Col, { ColProps } from 'react-bootstrap/esm/Col';
import { EscrowDisplay, Subheading, Headshot } from '.';
import Row from 'react-bootstrap/esm/Row';
import { useDisplayName } from '@Hooks';
import { handleKeyDown } from '@Utils';
import { AxiosResponse } from 'axios';
import { Props } from '@Types';
import API from '@API';
import {
  Bill,
  Secret,
  PolData,
  DisplayName,
  PolDonations,
  PolsOnParade,
} from '@Interfaces';
import './style.css';

type PolSelectionProps = {
  setSecrets: Dispatch<SetStateAction<Secret[]>>;
  startLoadingDossier: TransitionStartFunction;
  description?: PolData;
  middleName?: string;
  firstName: string;
  lastName: string;
  chamber: string;
  info: PolData;
  name: string;
  bill?: Bill;
  id: string;
};

// donee/Pol unit
const PolSelection = ({
  setHasSelectedBefore,
  startLoadingDossier,
  switchToErrorScreen,
  hasSelectedBefore,
  totalCelebrations,
  polsOnParade,
  description,
  selectedPol,
  middleName,
  firstName,
  setSecrets,
  selectPol,
  lastName,
  isMobile,
  chamber,
  setPAI,
  bill,
  info,
  name,
  id,

  ...props
}: Props & PolSelectionProps) => {
  const [displayName, { setDisplayName }] = useDisplayName({
    middle: middleName ?? '',
    first: firstName,
    last: lastName,
    isMobile,
  });

  useLayoutEffect(() => setDisplayName(19), [setDisplayName]);

  const [polDonationsInEscrow, setPolDonationsInEscrow] = useState(0);
  const [polDonationTally, setPolDonationTally] = useState(0);

  const tallyDonations = useCallback(
    (id: string) => {
      let [polDonations] = (totalCelebrations as PolDonations[]).filter(
        (c) => c.pol_id === id
      );
      if (!polDonations) {
        return;
      } else {
        setPolDonationTally(polDonations.count);
        setPolDonationsInEscrow(polDonations.donation);
      }
    },
    [totalCelebrations]
  );

  useEffect(() => tallyDonations(id), [id, tallyDonations]);

  // ref handles activation of pol selection, scrolling and focus
  const selectedPolRef = useRef<MutableRefObject<null>>();

  const isDifferentPol = useMemo(() => {
    return (
      (id && description && id !== (description as PolData).id) ||
      !id ||
      !description
    );
  }, [id, description]);

  // triggered by Politician selection => loads Dossier
  useLayoutEffect(() => {
    if (isDifferentPol) {
      return;
    } else {
      startLoadingDossier(() => {
        (API.getPAI as (id: string) => any)((description as PolData).id)
          .then((res: AxiosResponse) => {
            const data = res.data;
            return data;
          })
          .then((data: AxiosResponse) => {
            (setPAI as Dispatch<SetStateAction<number>>)(
              data as unknown as number
            );
            API.getHealthRelatedContributions(
              (description as PolData).crp_id
            )
              .then((res: AxiosResponse) => {
                const data = res.data;
                return data;
              })
              .then((data: AxiosResponse) => {
                (setSecrets as Dispatch<SetStateAction<Secret[]>>)(
                  data as unknown as Secret[]
                );
              });
          })
          .catch((err: Error) =>
            (switchToErrorScreen as (err: Error) => void)(err)
          );
      });
    }
  }, [
    startLoadingDossier,
    switchToErrorScreen,
    isDifferentPol,
    description,
    setSecrets,
    setPAI,
    id,
  ]);

  const [selectionClassName, setSelectionClassName] = useState<string>(''); // img border effect
  // keyboard method of choosing pol feeds into Select handler

  const handleSelect = useCallback(() => {
    if (isDifferentPol) (selectPol as (pol: PolData) => void)({ ...info });
    (setHasSelectedBefore as Dispatch<SetStateAction<boolean>>)(true);
    (setSecrets as Dispatch<SetStateAction<Secret[]>>)([
      { industry: '', total: 0, rank: 1, indivs: 0, pacs: 0 },
    ]);
    // smooth scrolls pol into center view
    (
      (selectedPolRef as RefObject<MutableRefObject<null>>)
        .current as ColProps
    ).scrollIntoView({
      block: 'nearest',
      inline: isMobile
        ? hasSelectedBefore
          ? 'nearest'
          : 'start'
        : 'center',
      behavior: 'smooth',
    });
    // slap on a border to visually indicate selection
    (
      (
        (selectedPolRef as RefObject<MutableRefObject<null>>)
          .current as ColProps
      ).style as StyleHTMLAttributes<ColProps>
    ).className = 'pol-headshot-selected';
    setSelectionClassName(
      (
        (selectedPolRef as RefObject<MutableRefObject<null>>)
          .current as any
      ).style.className as string
    );
    // for the aforementioned accessibility
    (selectedPolRef as any).current.focus();
  }, [
    setHasSelectedBefore,
    hasSelectedBefore,
    setSecrets,
    isDifferentPol,
    selectPol,
    isMobile,
    info,
  ]);

  const nail = useMemo(() => {
    return {
      tabIndex:
        (polsOnParade as PolsOnParade).applied.length === 1 ? -1 : 0,
      styles:
        (polsOnParade as PolsOnParade).applied.length === 1
          ? { height: '357px !important' } // it's magic..
          : {},
    };
  }, [polsOnParade]);

  const isExpanded = useMemo(() => {
    return selectedPol !== null;
  }, [selectedPol]);

  return (
    <Col className={'pol-wrapper'} key={'pol-carousel-selection-' + id}>
      <Row>
        <Col
          id={id}
          style={nail.styles}
          ref={selectedPolRef}
          onClick={handleSelect}
          tabIndex={nail.tabIndex}
          aria-expanded={isExpanded}
          className={'pol-selection-col'}
          onKeyDown={(e) => handleKeyDown(e, handleSelect)}>
          <EscrowDisplay
            {...(displayName as DisplayName)}
            key={id + '-escrow-display-' + polDonationTally}
            donations={polDonationsInEscrow}
            tally={polDonationTally}
            id={id}
          />
          <Headshot
            {...props}
            id={description ? (description as PolData).id : ''}
            cls={selectionClassName}
            name={name}
            src={id}
          />
          <Subheading
            totalCelebrations={totalCelebrations}
            polsOnParade={polsOnParade}
            selectedPol={selectedPol}
            selectPol={selectPol}
            isMobile={isMobile}
            bill={bill}
            {...props}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(PolSelection);
