import React, { KeyboardEvent, MouseEvent, useMemo } from 'react';
import NavLink from 'react-bootstrap/esm/NavLink';
import NavItem from 'react-bootstrap/esm/NavItem';
import { ShowModal } from '@Interfaces';
import { SplashPath } from '@Types';
import './style.css';

interface SideLinkProps {
  openModal?: (modal: keyof ShowModal | string) => void;
  handler?: (e: KeyboardEvent | MouseEvent) => void;
  setSplash?: (path: SplashPath) => void;
  label?: string | React.ReactElement;
  modal?: keyof ShowModal;
  href?: URL['href'];
  icon?: string;
  cls?: string;
}

const SideLink = ({
  cls,
  href,
  icon,
  label,
  modal,
  handler,
  openModal,
  setSplash,
}: SideLinkProps) => {
  const modalType = useMemo(() => {
      return modal && modal.charAt(1).toLowerCase() === modal.charAt(1)
        ? modal.toLowerCase()
        : modal;
    }, [modal]),
    linkLabel = useMemo(() => {
      return (
        <>
          &nbsp;
          {label
            ? label
            : modal &&
              (modal.charAt(0).toUpperCase() === ''
                ? modal.trim().charAt(0).toUpperCase() +
                  modal.trim().slice(1)
                : modal.charAt(0).toUpperCase() + modal.slice(1))}
        </>
      );
    }, [label, modal]);
  return href ? (
    <NavLink tabIndex={0} href={href} className={cls}>
      <i className={'icon-link iconic-pink bi-' + icon} />
      {linkLabel}
    </NavLink>
  ) : (
    <NavItem
      as={'a'}
      role={'tab'}
      tabIndex={0}
      className={cls + 'offcanvas nav-link'}
      onClick={(arg0) => {
        openModal && modalType
          ? openModal(modalType)
          : (handler && handler(arg0 as MouseEvent)) ||
            (setSplash as (path: SplashPath) => void)(label as SplashPath);
      }}
      onKeyDown={(arg0) => {
        openModal && modalType
          ? openModal(modalType)
          : (handler && handler(arg0 as KeyboardEvent)) ||
            (setSplash as (path: SplashPath) => void)(label as SplashPath);
      }}>
      <>
        {icon && <i className={'icon-link iconic-pink bi-' + icon} />}
        {linkLabel}
      </>
    </NavItem>
  );
};

export default React.memo(SideLink);
