import React, { useMemo, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Props } from '@Types';
import './style.css';

type VideoPlayerProps = {
  isInsideModal?: boolean;
  altVideoPath: string;
  videoPath: string;
  light?: boolean;
  height?: string;
  width?: string;
};

const VideoPlayer = ({
  light = false,
  isDesktop,
  videoPath,
  altVideoPath,
  isInsideModal,
  width = '30vw',
  height = '34vh',
}: Props & VideoPlayerProps) => {
  const [videoURLForBrowser, setVideoURLForBrowser] = useState(videoPath);

  const topStyle = useMemo(() => {
      return isDesktop && isInsideModal
        ? { top: height.substring(0, height.length - 2) + '%' }
        : {};
    }, [height, isDesktop, isInsideModal]),
    heightStyle = useMemo(() => {
      return isDesktop && isInsideModal ? { height: height } : {};
    }, [height, isDesktop, isInsideModal]);

  return (
    <div className='player-wrapper' style={heightStyle}>
      <ReactPlayer
        onError={() => setVideoURLForBrowser(altVideoPath)}
        height={isDesktop ? height : '100%'}
        width={isDesktop ? width : '100%'}
        className={'react-player'}
        url={videoURLForBrowser}
        style={topStyle}
        light={light}
        playing
        controls
        muted
        loop
      />
    </div>
  );
};

export default React.memo(VideoPlayer);
