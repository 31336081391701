import React from 'react';
import { Props } from '@Types';
import { CITATIONS } from '@CONSTANTS';
import { visitCitation } from '@Utils';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Image from 'react-bootstrap/esm/Image';
import Stack from 'react-bootstrap/esm/Stack';
import Container from 'react-bootstrap/esm/Container';
import PP_IMG_SRC from '../../../img/citations/pp/pp-logo.svg';
import OS_IMG_SRC from '../../../img/citations/os/os-logo-box.svg';
import './style.css';

const Footer = ({ isMobile }: Props) => (
  <Container fluid className='footer'>
    <Row>
      <Col className='paid-for mb-1'>
        <p className='mt-2 mb-1'>
          Paid for by <span className='powerback'>POWERBACK</span> (
          <span className='link-appearance'>powerback.me</span>) and not
          authorized by any candidate or candidate's committee.
          <br />
          <br />
          Contributions of gifts to political candidates via{' '}
          <span className='powerback'>POWERBACK</span> as a conduit are not
          deductible as charitable contributions for Federal income tax
          purposes.
        </p>
      </Col>
    </Row>
    <Stack direction='horizontal' className='citations' gap={3}>
      <Image
        height={CITATIONS.PP.s[isMobile ? 'sm' : 'lg']}
        alt={'ProPublica Data Store logo'}
        onClick={() => visitCitation('PP')}
        className={'citation'}
        src={PP_IMG_SRC}
      />
      <Row className='footer-links pt-1'>
        {/* GreenGeeks seal of green energy */}
        <Col className='gg'>
          <Image
            alt={
              '300% Green Powered Web Hosting certification from Green Geeks'
            }
            onClick={() => visitCitation('GG')}
            src={CITATIONS.GG.i}
            width={113}
            height={26}
          />
        </Col>
        <Col className='donation-politics'>donation politics</Col>
        <Col>
          ©{new Date().getFullYear() + ' '}
          <a href='/'>POWERBACK</a>
        </Col>
      </Row>
      <Image
        height={CITATIONS.OS.s[isMobile ? 'sm' : 'lg']}
        style={{ filter: 'invert(100)' }} // move this into CSS
        onClick={() => visitCitation('OS')}
        alt={'OpenSecrets.org logo'}
        className={'citation'}
        src={OS_IMG_SRC}
      />
    </Stack>
  </Container>
);

export default React.memo(Footer);
