import { setTokens } from '@Utils';
import { INIT } from '@CONSTANTS';
import API from '@API';

export const loadUser = (
  user,
  setCookie,
  setLoggedIn,
  setUserData,
  setShowAlert,
  setShowModal,
  setUserEntryForm,
  switchToErrorScreen,
  setUserFormValidated
) => {
  setTokens(user.accessToken, user.refreshToken, setCookie);
  API.getCelebrationsByUserId(user.id) // api call -- user donations
    .then((w) => {
      const { data: donations } = w;
      let { accessToken, refreshToken, ...z } = user;
      z.donations = donations;
      setUserData({ ...z });
    })
    .catch((err) => switchToErrorScreen(err));
  setLoggedIn(); // log in
  setUserFormValidated((s) => (s = false)); // clear form validation
  setUserEntryForm((s) => ({ ...s, ...INIT.credentials })); // user entry form obj
  setShowAlert((s) => ({
    ...s,
    login: true,
    logout: false,
    activate: false,
    err: false,
  }));
  setShowModal((s) => ({ ...s, credentials: false }));
};
