import React, {
  useMemo,
  Dispatch,
  useState,
  useTransition,
  SetStateAction,
  useLayoutEffect,
} from 'react';
import { Secret, PolData, DossierIds, PolsOnParade } from '@Interfaces';
import Placeholder from 'react-bootstrap/esm/Placeholder';
import Container from 'react-bootstrap/esm/Container';
import { transformPolData, calcDays } from '@Utils';
import Stack from 'react-bootstrap/esm/Stack';
import Row from 'react-bootstrap/esm/Row';
import { PolSelection, Loading } from '.';
import { INIT } from '@CONSTANTS';
import { Props } from '@Types';
import './style.css';

const DossierCard = React.lazy(() => import('./DossierCard'));

const PolCarousel = ({
  setPolsAreLoaded,
  showCosponsors,
  polsAreLoaded,
  polsOnParade,
  cosponsorIds,
  isMobile,
  polData,
  ...props
}: Props) => {
  const [isLoadingPols, startLoadingPols] = useTransition();

  // carousel items after user manipulation
  const applied = useMemo(() => {
    return !polsOnParade ? [] : polsOnParade.applied;
  }, [polsOnParade]);

  useLayoutEffect(() => {
    if (applied && !polsAreLoaded)
      startLoadingPols(() => {
        if (applied.length) (setPolsAreLoaded as () => void)();
      });
  }, [applied, polsAreLoaded, setPolsAreLoaded]);

  const handleCenteringPolSelection = useMemo(() => {
    if (!polsAreLoaded) return;
    // turn this into a switch activity for different screen sizes *****************************************
    else if (applied.length < 8 && applied.length > 3) {
      return 'pol-row short';
    } else if (applied.length === 3) {
      return 'pol-row three';
    } else if (applied.length === 2) {
      return 'pol-row two';
    } else if (applied.length === 1) {
      return 'pol-row one';
    } else return 'pol-row';
  }, [polsAreLoaded, applied]);

  const filteredByCosponsorship = (
    polsOnParade as PolsOnParade
  ).applied.filter((pol) =>
    (cosponsorIds as string[]).length > 0 && (showCosponsors as boolean)
      ? (cosponsorIds as string[]).includes(pol.id)
      : pol
  );

  // slideout PAI and contributor records "dossier"
  const [isLoadingDossier, startLoadingDossier] = useTransition(),
    [dossier, setDossier] = useState<DossierIds>(INIT.dossier),
    [secrets, setSecrets] = useState<Secret[]>([INIT.secret]),
    [PAI, setPAI] = useState<number>(),
    [prevPAI, setPrevPAI] = useState(PAI);
  if (prevPAI !== PAI) {
    setDossier({
      id: (polData as PolData).id,
      crp_id: (polData as PolData).crp_id,
    });
    setPrevPAI(PAI);
  }

  // to indicate if user has already made a selection in the carousel. this is to make up for some weird behavior on the scrollbar once the side collapse Dossier is already open.
  const [hasSelectedBefore, setHasSelectedBefore] = useState(false);

  return (
    <Container id={'pol-carousel'} className={'pol-carousel'}>
      {isLoadingPols ||
      (!polsAreLoaded && !handleCenteringPolSelection) ? (
        <Placeholder
          as={Container}
          animation={'wave'}
          className={'pol-carousel-placeholder'}>
          <Placeholder
            className={'pol-row-placeholder'}
            animation={'wave'}
            as={Row}>
            <Loading />
          </Placeholder>
        </Placeholder>
      ) : (
        <Row
          className={'pol-carousel ' + handleCenteringPolSelection}
          style={
            filteredByCosponsorship.length < 9 && !isMobile
              ? { justifyContent: 'center' }
              : {}
          }>
          {polsOnParade &&
            filteredByCosponsorship.length > 0 &&
            filteredByCosponsorship.map((choice) => (
              <Stack
                key={choice.id + '-pol-selection'}
                direction={'horizontal'}
                className={'pol-w-card'}>
                <PolSelection
                  {...props}
                  {...choice}
                  id={choice.id}
                  polData={polData}
                  isMobile={isMobile}
                  crpId={choice.crp_id}
                  setSecrets={setSecrets}
                  lastName={choice.last_name}
                  polsOnParade={polsOnParade}
                  firstName={choice.first_name}
                  state={choice.roles[0].state}
                  info={transformPolData(choice)}
                  middleName={choice.middle_name}
                  description={polData as PolData}
                  twitter={choice.twitter_account}
                  chamber={choice.roles[0].chamber}
                  rank={choice.roles[0].state_rank}
                  district={choice.roles[0].district}
                  hasSelectedBefore={hasSelectedBefore}
                  FEC_id={choice.roles[0].fec_candidate_id}
                  startLoadingDossier={startLoadingDossier}
                  setHasSelectedBefore={setHasSelectedBefore}
                  end_date={calcDays(choice.roles[0].end_date)}
                  start_date={calcDays(choice.roles[0].start_date)}
                  name={choice.first_name + ' ' + choice.last_name}
                  setPAI={setPAI as Dispatch<SetStateAction<number>>}
                  key={'pol-selection-card-' + choice.id}
                />

                <DossierCard
                  key={choice.id + '-dossier'}
                  hasSelectedBefore={hasSelectedBefore}
                  isLoadingDossier={isLoadingDossier}
                  crpId={choice.crp_id as string}
                  secrets={secrets as Secret[]}
                  PAI={PAI as number}
                  isMobile={isMobile}
                  selected={dossier}
                  id={choice.id}
                  {...props}
                />
              </Stack>
            ))}
        </Row>
      )}
    </Container>
  );
};

export default React.memo(PolCarousel);
