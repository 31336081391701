export const {
    entry,
    login,
    joinUp,
    passReset,
    passChange,
    passFormChange,
    userFormChange,
    donationFailure,
  } = require('./formSubmit'),
  { logOut } = require('./logOut'),
  { loadUser } = require('./loadUser'),
  { clearForm } = require('./clearForm'),
  { setTokens } = require('./setTokens'),
  { activation } = require('./activation'),
  { deleteUser } = require('./deleteUser'),
  { updateUser } = require('./updateUser'),
  { refreshToken } = require('./refreshToken'),
  { transformPolData } = require('./transformPolData');
