import React, { KeyboardEvent, MouseEvent, ReactNode } from 'react';
import Button from 'react-bootstrap/esm/Button';
import './style.css';

type Props = {
  label?: string;
  size?: 'sm' | 'lg';
  children?: ReactNode;
  onPress: (e?: KeyboardEvent | MouseEvent | undefined) => void;
};

const GenericBtn = ({ size = 'lg', label, children, onPress }: Props) => (
  <Button
    onKeyDown={(e: KeyboardEvent) => {
      if (
        !(
          e.type === 'click' ||
          (e.type === 'keydown' && (e.key === ' ' || e.key === 'Enter'))
        )
      )
        return;
      else onPress(e as KeyboardEvent<HTMLButtonElement>);
    }}
    onClick={(e: MouseEvent) =>
      onPress(e as MouseEvent<HTMLButtonElement>)
    }
    size={size}
    tabIndex={0}
    type={'button'}
    aria-label={label}
    className={'generic-btn'}>
    {label ? label : children}
  </Button>
);

export default React.memo(GenericBtn);
