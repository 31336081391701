export const OPTIONS = {
  arrowColor: '#f9c',
  backgroundColor: '#9bad97',
  beaconSize: 36,
  overlayColor: 'rgba(0, 0, 0, 0.5)',
  primaryColor: '#cf9',
  spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  textColor: '#1e1e1e',
  width: '',
  zIndex: 100,
};
