module.exports = {
  capitalCaseify: (txt) => {
    return txt
      .toUpperCase()
      .split(' ')
      .reduce(
        (a, b) =>
          a.charAt(0) +
          a.slice(1).toLowerCase() +
          ' ' +
          b.charAt(0) +
          b.slice(1).toLowerCase()
      );
  },
};
