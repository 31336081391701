import React, {
  Dispatch,
  MouseEvent,
  useCallback,
  KeyboardEvent,
  SetStateAction,
  MouseEventHandler,
  KeyboardEventHandler,
} from 'react';
import { TabLink } from '@Components/interactive';
import { ContinueBtn } from '@Components/buttons';
import { CredentialsPath, Props } from '@Types';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/esm/Nav';
import Row from 'react-bootstrap/esm/Row';
import { ShowModal } from '@Interfaces';
import { capitalCaseify } from '@Utils';
import './style.css';

const NavUser = ({
  setCredentialsPath,
  handleLogOut,
  setShowModal,
  loggedIn,
  isMobile,
  route,
}: Props) => {
  const openCredentialsModal = useCallback(
    (e: KeyboardEvent<Element> | MouseEvent<Element>) =>
      (setCredentialsPath as Dispatch<SetStateAction<CredentialsPath>>)(
        capitalCaseify(
          (e.target as HTMLElement).textContent as string
        ) as CredentialsPath
      ),
    [setCredentialsPath]
  );

  const openAccountModal = useCallback(
    () =>
      (setShowModal as Dispatch<SetStateAction<ShowModal>>)((s) => ({
        ...s,
        account: true,
      })),
    [setShowModal]
  );

  return (
    <Col lg={3}>
      <Nav className='nav-links flex-row'>
        <Row className='account-logio--row'>
          {(loggedIn && (
            <Col
              lg={12}
              onKeyDown={(e: KeyboardEvent) => {
                if (
                  !(
                    e.type === 'click' ||
                    (e.type === 'keydown' &&
                      (e.key === ' ' || e.key === 'Enter'))
                  )
                )
                  return;
                else openAccountModal();
              }}
              onClick={openAccountModal}>
              <TabLink topic={'Account'} />
            </Col>
          )) || (
            <Col lg={12}>
              <ContinueBtn
                size={'sm'}
                classProp={''}
                hidden={false}
                type={'button'}
                variant={'dark'}
                disabled={false}
                label={'JOIN NOW'}
                isMobile={isMobile}
                handleClick={openCredentialsModal}
              />
            </Col>
          )}
          {(loggedIn && (
            <Col xs={'auto'}>
              <span
                tabIndex={0}
                onClick={
                  handleLogOut as unknown as (
                    e: MouseEvent
                  ) => MouseEventHandler
                }
                onKeyDown={
                  handleLogOut as unknown as (
                    e: KeyboardEvent
                  ) => KeyboardEventHandler
                }
                className={'natural-link navbar-logio'}>
                Sign out
              </span>
            </Col>
          )) ||
            (route.name === 'main' && (
              <Col xs={'auto'}>
                <span
                  onKeyDown={(e: KeyboardEvent) => {
                    if (
                      !(
                        e.type === 'click' ||
                        (e.type === 'keydown' &&
                          (e.key === ' ' || e.key === 'Enter'))
                      )
                    )
                      return;
                    else openCredentialsModal(e);
                  }}
                  className={'natural-link navbar-logio'}
                  onClick={openCredentialsModal}
                  tabIndex={0}>
                  Sign in
                </span>
              </Col>
            ))}
        </Row>
      </Nav>
    </Col>
  );
};

export default React.memo(NavUser);
