export const {
  Loading,
  PolCarousel,
  SwipingHand,
  PolSelection,
} = require('./PolCarousel'),
  Logio = require('./Logio').default,
  BtnGrid = require('./BtnGrid').default,
  TabLink = require('./TabLink').default,
  LinkGroup = require('./LinkGroup').default,
  QAccordian = require('./QAccordian').default,
  NavTabLinks = require('./NavTabLinks').default,
  VideoPlayer = require('./VideoPlayer').default;
