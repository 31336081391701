import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  SyntheticEvent,
  SetStateAction,
  KeyboardEvent,
  ReactElement,
  EventHandler,
  useCallback,
  Dispatch,
  useMemo,
} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { ShowModal } from '@Interfaces';
import { handleKeyDown } from '@Utils';
import { HideEvent } from '@Types';
import './style.css';

type ShowModalKey = keyof ShowModal;

type HandleHide = KeyboardEventHandler<HTMLElement> &
  EventHandler<SyntheticEvent<any, CloseEvent>> &
  MouseEventHandler<HTMLElement> &
  ((node: HTMLElement) => any) &
  (() => void);

type StyledModalProps = {
  overrideOnClick?: (e: HideEvent) => HideEvent | (() => void);
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  onExited?: (node: HTMLElement) => any;
  onExit?: (node: HTMLElement) => any;
  heading: ReactElement | string;
  backdrop?: boolean | 'static';
  size?: 'sm' | 'lg' | 'xl';
  closeButton?: boolean;
  footer?: ReactElement;
  showModal: ShowModal;
  onEnter?: () => void;
  type: keyof ShowModal;
  body: ReactElement;
  tabIdx?: 0 | 1;
};

const StyledModal = ({
  overrideOnClick,
  setShowModal,
  closeButton,
  showModal,
  backdrop,
  onExited,
  heading,
  onEnter,
  footer,
  onExit,
  tabIdx = 0,
  body,
  size = 'lg',
  type,
}: StyledModalProps) => {
  const label = useMemo(() => {
    return type.toLowerCase() + '-modal';
  }, [type]);

  const handleHide = useCallback(
    (e: any) => {
      if (overrideOnClick) return overrideOnClick(e);
      else
        setShowModal((s: ShowModal) => ({
          ...s,
          [type as keyof ShowModal]: false,
        }));
    },
    [type, setShowModal, overrideOnClick]
  );

  const handleKeyboardHide = useCallback(
    (e: KeyboardEvent) => {
      return handleKeyDown(e, handleHide as HandleHide);
    },
    [handleHide]
  );

  const handleShow = useMemo(() => {
    if (showModal && type) {
      return showModal[type as ShowModalKey];
    } else return false;
  }, [type, showModal]);

  return (
    <Modal
      centered
      id={label}
      size={size}
      onExit={onExit}
      onEnter={onEnter}
      show={handleShow}
      aria-label={label}
      backdrop={backdrop}
      onExited={onExited}
      className={'styled-modal'}
      onHide={handleHide as HandleHide}>
      {heading && (
        <Modal.Header
          closeButton={closeButton}
          closeVariant={'white'}
          closeLabel={'close'}>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>
        {body}
        {footer && (
          <Modal.Footer
            onKeyDown={handleKeyboardHide}
            onClick={handleHide}
            tabIndex={tabIdx}>
            {footer}
          </Modal.Footer>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(StyledModal);
