import React, { useState, useMemo } from 'react';
import { PolData, Secret, Settings, Subheading } from '@Interfaces';
import Collapse from 'react-bootstrap/esm/Collapse';
import { TABLE_CLASSNAME } from './constants';
import Card from 'react-bootstrap/esm/Card';
import Fade from 'react-bootstrap/esm/Fade';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { DossierProps } from '@Types';
import { DOSSIER } from '@CONSTANTS';
import './style.css';

const DataCitation = React.lazy(() => import('./subcomps/Citation')),
  DossierSubheading = React.lazy(() => import('./subcomps/Subheading')),
  Funds = React.lazy(() => import('./subcomps/Funds/Funds')),
  FundsPlaceholder = React.lazy(
    () => import('./subcomps/Funds/placeholder')
  ),
  PAIBar = React.lazy(() => import('./subcomps/PAI'));

const DossierCard = ({
  hasSelectedBefore,
  isLoadingDossier,
  isMobile,
  selected,
  settings,
  secrets,
  crpId,
  PAI,
  id,
}: DossierProps) => {
  const [prevSelected, setPrevSelected] = useState(selected),
    [blankDossier, setBlankDossier] = useState(false);

  const thisIsIn = useMemo(() => {
      return (
        id !== '' &&
        id === selected.id &&
        crpId !== '' &&
        crpId === selected.crp_id
      );
    }, [id, crpId, selected]),
    isNewSelection = useMemo(() => {
      return (
        (prevSelected.id &&
          prevSelected.id !== id &&
          selected.id === id) !== !hasSelectedBefore
      );
    }, [id, selected.id, prevSelected.id, hasSelectedBefore]);

  if (prevSelected !== selected) {
    if (isNewSelection) setBlankDossier(false);
    else setBlankDossier(true);
    setPrevSelected(selected);
  }

  const animation = isMobile ? 'glow' : 'wave';

  return (
    <Collapse
      mountOnEnter
      unmountOnExit
      dimension={'width'}
      role={DOSSIER.role}
      in={thisIsIn && !isNewSelection}>
      <div>
        <Card body className={'pol-dossier'}>
          <Fade unmountOnExit mountOnEnter in>
            {blankDossier ? (
              <></>
            ) : (
              <>
                <Row
                  className={'pai ds-flex flex-row-reverse mt-2 mt-lg-0'}>
                  <Col xs={2} lg={12}>
                    {/* PARTISAN AUTONOMY INDEX */}
                    <DossierSubheading
                      infoPlacement={isMobile ? 'top' : 'top-end'}
                      sub={'pai' as unknown as Subheading}
                      settings={settings as Settings}
                      id={(selected as PolData).id}
                      isMobile={isMobile}
                    />
                  </Col>
                  <Col xs={10} lg={12}>
                    <PAIBar
                      isLoadingDossier={isLoadingDossier}
                      isMobile={isMobile}
                      selected={selected}
                      PAI={PAI}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DataCitation label={DOSSIER.pai.cit} cit={'PP'} />
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col xs={12}>
                    {/* FUNDS RAISED */}
                    <DossierSubheading
                      infoPlacement={isMobile ? 'top' : 'left-end'}
                      sub={'top' as unknown as Subheading}
                      settings={settings as Settings}
                      id={(selected as PolData).id}
                      isMobile={isMobile}
                    />
                  </Col>

                  {(!isLoadingDossier && (
                    <Col xs={12}>
                      <Funds
                        key={crpId + '-funds-table'}
                        secrets={secrets as Secret[]}
                        tableClass={TABLE_CLASSNAME}
                        isMobile={isMobile}
                      />
                    </Col>
                  )) || (
                    <Col xs={12}>
                      <FundsPlaceholder
                        tableClass={TABLE_CLASSNAME}
                        animation={animation}
                      />
                    </Col>
                  )}

                  <Col>
                    <DataCitation label={DOSSIER.top.cit} cit={'OS'} />
                  </Col>
                </Row>
              </>
            )}
          </Fade>
        </Card>
      </div>
    </Collapse>
  );
};

export default React.memo(DossierCard);
