import React, {
  KeyboardEvent,
  SetStateAction,
  useCallback,
  MouseEvent,
  Dispatch,
} from 'react';
import Container from 'react-bootstrap/esm/Container';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';
import { ShowModal, UserData } from '@Interfaces';
import { Props, SplashPath } from '@Types';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { BottomPanel } from './Panels';
import SideNavHeader from './Header';
import './style.css';

type SideNavProps = {
  handleLogOut: ((e: KeyboardEvent & MouseEvent) => void) &
    ((
      e: KeyboardEvent<Element> | MouseEvent<Element, MouseEvent>
    ) => void);
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
};

const SideNav = ({
  handleLogOut,
  setShowModal,
  showModal,
  setSplash,
  loggedIn,
  userData,
  setShow,
  show,
  ...props
}: Props & SideNavProps) => {
  const handleClose = useCallback(() => setShow(false), [setShow]);
  const openModal = useCallback(
    (modal: keyof ShowModal | string) =>
      (setShowModal as Dispatch<SetStateAction<ShowModal>>)((s) => ({
        ...s,
        [modal.trim()]: true,
      })),
    [setShowModal]
  );
  return (
    <Offcanvas
      show={show}
      backdrop={true}
      autoFocus={false}
      enforceFocus={false}
      onHide={handleClose}>
      <Offcanvas.Body>
        <Container>
          <Row className='flex-column justify-content-around'>
            <Col>
              <SideNavHeader handleClose={handleClose} {...props} />
            </Col>
            <Col>
              <hr className='sidebar-hline pb-3' />
            </Col>
            <Col>
              <BottomPanel
                setSplash={
                  setSplash as Dispatch<SetStateAction<SplashPath>>
                }
                username={userData ? (userData as UserData).username : ''}
                loggedIn={loggedIn as boolean}
                handleLogOut={
                  handleLogOut as (e: KeyboardEvent | MouseEvent) => void
                }
                openModal={openModal}
                {...userData}
              />
            </Col>
          </Row>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default React.memo(SideNav);
