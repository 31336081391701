import axios from 'axios';

export const setTokens = (auth, refresh, setCookie) => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${auth}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  setCookie('refreshToken', refresh, [
    {
      path: '/',
      // secure: true,
      // domain: 'localhost',
      // sameSite: 'strict',
      // partitioned: false,
      // httpOnly: true,
    },
  ]);
};
